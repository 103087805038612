import { Formik } from 'formik';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import axios from '../../features/axiosInstances/axios';
import * as yup from 'yup';

import { Box } from '@mui/material';
import { updatePhoneNumber, user } from '../../features/userSlice';
import Swal from 'sweetalert2';
import Select from 'react-select'

interface PhoneEntryModalProps {
    modalVisible: boolean,
    closeMod: () => void
}
const options = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' }
]
const PhoneUpdate = ({ modalVisible, closeMod }: PhoneEntryModalProps) => {
    const userInfos = useAppSelector(user);
    const [selectedAdress, SetSelectedAdress] = useState("06");
    const [url, SetUrl] = useState("");
    const [phonenbr, SetPhonenbr] = useState("");
    const [page, SetSelectedPage] = useState(1);
    const dispatch = useAppDispatch();

    const phonenbrSchema = yup.object({
        phone: yup.number().required('Numéro obligatoire'),
    });
    const verifSchema = yup.object({
        code: yup.number().required('Code obligatoire').default(1111111),
    });
    return (
        modalVisible ? (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-full my-6 mx-auto max-w-xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-xl font-semibold">
                                    Changer votre numéro de téléphone
                                </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={closeMod}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative w-full flex-auto pt-5 px-5">
                                {page == 1 ?
                                    <Formik
                                        validationSchema={phonenbrSchema}
                                        initialValues={{ phone: 0 }}
                                        onSubmit={(values) => {
                                            let phone = '+33' + selectedAdress + values.phone
                                            axios.patch("/me/mobile", {
                                                "phone_number": phone
                                            }, {
                                                headers: { "Authorization": `Bearer ${userInfos.token}` }
                                            })
                                                .then(res => {
                                                    SetSelectedPage(2)
                                                    SetUrl(res.data.verification_url)
                                                    SetPhonenbr(phone)

                                                })
                                                .catch(error => {

                                                    if (error.response.status === 422) {

                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Oops...',
                                                            text: 'Données invalides !',
                                                        })

                                                    } else {

                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Oops...',
                                                            text: "Une erreur de vérification de téléphone s'est produite !",
                                                        })
                                                    }
                                                })
                                        }}
                                    >
                                        {({ handleChange,
                                            handleSubmit,
                                            values,
                                            errors,
                                            isValid }) => (
                                            <Box alignItems="center" justifyContent="space-between">
                                                <span className="w-1/2 text-gray-500">Numéro de phone</span>

                                                <Box alignItems="center" justifyContent="space-between">
                                                    <Box flexDirection="row" alignItems="center" justifyContent="center">


                                                        <div className="flex flex-row justify-center align-middle pt-5">
                                                            <img src={"https://icons.iconarchive.com/icons/wikipedia/flags/1024/FR-France-Flag-icon.png"}
                                                                style={{
                                                                    
                                                                    height: 45,
                                                                    width: 45,
                                                                }}
                                                            />
                                                            <span className="px-2 pt-2 text-center">+33</span>
                                                            <Select className="w-1/6"
                                                                options={options}
                                                                onChange={selectedOption =>
                                                                    SetSelectedAdress(selectedOption.value)}

                                                            />
                                                            <input
                                                                className="px-2 mx-2"
                                                                placeholder="xxxxxxx"
                                                                value={values.phone.toString()}
                                                                onChange={handleChange('phone')}

                                                            />
                                                        </div>
                                                    </Box>
                                                </Box>
                                                {errors.phone &&
                                                    <span className="text-gray-500">{errors.phone}</span>
                                                }


                                                <div className="flex flex-row justify-end m-5">
                                                    <button
                                                        className="bg-snack-green-original text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={closeMod}
                                                    >
                                                        fermer
                                                    </button>
                                                    <button
                                                        className="bg-snack-pink-original text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        Envoyer code
                                                    </button>
                                                </div>

                                            </Box>
                                        )}
                                    </Formik>
                                    :
                                    <Formik
                                        validationSchema={verifSchema}
                                        initialValues={{ code: "" }}
                                        onSubmit={(values) => {
                                            let code = values.code;

                                            axios.post(url, {
                                                "code": code
                                            }, {
                                                headers: { "Authorization": `Bearer ${userInfos.token}` }
                                            })
                                                .then(res => {
                                                    SetSelectedPage(1)
                                                    dispatch(updatePhoneNumber(phonenbr))

                                                })
                                                .then(closeMod)
                                                .catch(error => {
                                                    console.log("verif err ::", error.response.status);
                                                    if (error.response.status === 422) {
                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Oops...',
                                                            text: 'Code invalide !',
                                                        })

                                                    } else {
                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Oops...',
                                                            text: "Une erreur de vérification de code s'est produite !",
                                                        })
                                                    }
                                                })

                                        }}
                                    >
                                        {({ handleChange,
                                            handleSubmit,
                                            values,
                                            errors,
                                            isValid }) => (<Box >
                                            <Box className="flex flex-row justify-items-center align-middle" >
                                                <span className="w-1/2 text-gray-500">Entrez le code:</span>

                                                    <input
                                                        placeholder="xxxx"
                                                        value={values.code}
                                                        onChange={handleChange('code')}
                                                    />
                                               
                                                {errors.code &&
                                                    <span className="w-1/2 text-gray-500">{errors.code}</span>
                                                }
                                                
                                            </Box>
                                            <div className="flex flex-row justify-end m-5">
                                            <button
                                                className="bg-snack-green-original text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={closeMod}
                                            >
                                                Fermer
                                            </button>
                                            <button
                                                className="bg-snack-pink-original text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => handleSubmit()}
                                            >
                                                Changer
                                            </button>
                                        </div>
                                        </Box>
                                        )}
                                    </Formik>
                                }

                            </div>


                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null
    )
}
export default PhoneUpdate;