import axios from 'axios';
let url = "";
if (true) {
    url = "https://api-dashboard.snack-time.fr/api"
} else {
    url = "http://127.0.0.1:8000/api"
}

const instance = axios.create({
    baseURL: url,
    headers: {
        "Content-type": "application/json",
    }
});

export default instance;