import { axiosDash, axiosApi } from '../axiosInstances'

export const getAllCustomisableVariants = (/* token */) => {
    return axiosDash.get("/variants?isCustomizable=true", /* {
        headers: { "Authorization": `Bearer ${token}` }
    } */);
} 
export const getAllCustomisableVariantType = (id) => {
    return axiosDash.get("/variants?isCustomizable=true&dessert="+id, /* {
        headers: { "Authorization": `Bearer ${token}` }
    } */);
} 
export const getOneVariant = (id) => {
    return axiosDash.get("/variants/"+id, /* {
        headers: { "Authorization": `Bearer ${token}` }
    } */);
} 