import { useState } from 'react';
import SimpleBarReact from 'simplebar-react';

import UserBar from './UserBar';
import Logo from '../../assets/images/logo-client-app.png';
import { Book, CupCake, Home, Info, Invoice, List } from './icons';

import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from "react-router-dom";
import { AttachFile, Cake } from '@mui/icons-material';

interface MyLinkProps extends LinkProps {
    icon: (fill: string) => void
}

function Sidebar() {
    const [showSidebar, setShowSidebar] = useState('-left-64');
    function CustomLink({ icon, children, to, ...props }: MyLinkProps) {
        let resolved = useResolvedPath(to);
        let match = useMatch({ path: resolved.pathname, end: true });

        return (
            <div>
                <NavLink
                    className={(match ? "block text-white rounded-md bg-snack-green-original w-full pl-8 py-2" : "text-snack-gray-menu block rounded-md w-full pl-8 py-2")}
                    to={to}
                    end
                    {...props}
                    onClick={() => setShowSidebar('-left-64')}
                >
                    <div className='flex items-center'>
                        {icon(`${match ? "#fff" : "#A5ADC6"}`)}
                        {children}
                    </div>
                </NavLink>
            </div>
        );
    }
    return (
        <>
            <UserBar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} shadow-lg  flex-row flex-nowrap overflow-hidden z-50  transition-all duration-300`}
            >
                <div className="flex-col bg-white  overflow-hidden items-stretch min-h-full max-h-full  shadow-xl w-64 flex-nowrap pb-4 relative">
                    <a
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                        className="pt-2 sticky w-full flex justify-center  "
                    >
                        <img src={Logo} alt="logo"
                            style={{
                                height: 90,
                                width: 'auto',
                            }} />

                    </a>
                    {/* <hr className=" min-w-full" /> */}
                    <SimpleBarReact style={{ maxHeight: "calc(100vh - 150px)" }}>
                        <div className="flex flex-col pt-5">
                            <ul className="flex-col  flex list-none">
                                <li className='px-10'>
                                    <h1 className={'py-2 mb-1 text-2xl text-snack-blue-dark font-bebas border-b-2 '}>DASHBOARD</h1>
                                </li>
                                <li className=" px-2 my-2">
                                    <CustomLink
                                        to="/"
                                        icon={(fill) => (<div className='pr-2'><Home fill={fill} /></div>)}
                                    >
                                        Accueil
                                    </CustomLink>
                                </li>
                                <li className='px-10'>
                                    <h1 className={'py-2 mb-1 text-2xl text-snack-blue-dark font-bebas border-b-2 '}>DESSERTS</h1>
                                </li>
                                <li className="px-2 my-2">
                                    <CustomLink
                                        to="menu"
                                        icon={(fill) => (<div className='pr-2'><Book fill={fill} /></div>)}
                                    >
                                        Menu
                                    </CustomLink>
                                </li>
                                {/* <li className="px-2 my-2">
                                    <CustomLink
                                        to="personnalisez"
                                        icon={(fill) => (<div className='pr-2'><Cake fill={fill} /></div>)}
                                    >
                                        Personnalisez
                                    </CustomLink>
                                </li> */}
                                <li className="px-2 my-2">
                                    <CustomLink
                                        to="orders"
                                        icon={(fill) => (<div className='pr-2'><List fill={fill} /></div>)}
                                    >
                                        Mes commandes
                                    </CustomLink>
                                </li>
                                <li className="px-2 my-2">
                                    <CustomLink
                                        to="bonslivraison"
                                        icon={(fill) => (<div className='pr-2'><AttachFile fill={fill} /></div>)}
                                    >
                                        Mes Bons de livraison
                                    </CustomLink>
                                </li>
                                <li className="px-2 my-2">
                                    <CustomLink
                                        to="invoices"
                                        icon={(fill) => (<div className='pr-2'><Invoice fill={fill} /></div>)}
                                    >
                                        Mes factures
                                    </CustomLink>
                                </li>

                                {/* <li className="px-2 my-2">
                                    <CustomLink
                                        to="dessert_personnaliser"
                                        icon={(fill) => (<div className='pr-2'><CupCake fill={fill} /></div>)}
                                    >
                                        Personnalisez dessert
                                    </CustomLink>
                                </li> */}
                            </ul>
                        </div>
                    </SimpleBarReact>
                </div>

            </div >
        </>
    )
}
export default Sidebar;
