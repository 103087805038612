import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../app/hooks";
import { user } from "../features/userSlice";
import TableCard from './components/TableCard';
import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import { PDFViewer } from "@react-pdf/renderer";
import PagePDF from "./components/pdf/InvoicePDF";
import '../assets/css/orders.css'
import { Eye, Pdf } from "./components/icons";
import { getUserInvoices } from "../features/services";
import TableCardSubRows from "./components/TableCardSubRows";
import SubTableCard from "./components/SubTableCard";
import { axiosDash } from "../features/axiosInstances";

function Invoices() {
    const [showModalDetails, setShowModalDetails] = useState(false);

    const userInfos = useAppSelector(user)
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [viewPDF, setViewPDF] = useState(false)
    const [invoicesDetails, setInvoicesDetails] = useState([])
    const [total, setTotal] = useState({})
    const [invoice, setInvoice] = useState({})
    const [invoiceDetails, setInvoiceDetails] = useState(null)

    useEffect(() => {

        if (userInfos.profile.id) {

            getUserInvoices(userInfos.profile.id)
                .then(res => {
                    let all = res.data.data.reverse();

                    setInvoices(all);
                    console.log(res.data.data);
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue !',
                    })
                })
        }

    }, [])


    const COLUMNS = [
        {
            Header: 'Id',
            accessor: 'id',
        },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <span className={`inline-flex text-xs leading-5 font-medium rounded-full px-3 py-1 text-white ${row.original.status === "unpaid" ? 'bg-snack-pink-original ' : 'bg-snack-green-original '}  }  `}>
                        {row.original.status === "unpaid" ? "Non payée" :
                            "Payée"
                        }
                    </span>

                </div>),
        },
        /* {
            Header: 'Mode',
            accessor: 'mode',
            Cell: ({ row }) => (
                <div>
                    <span className={'px-2 inline-flex text-xs leading-5 font-semibold rounded-full  text-pink-900'}>
                        {row.original.mode === "COD" ? "En espèces" :
                            row.original.mode === "Card" ? "Carte Bancaire" :
                                row.original.mode === "TXN" ? "Transaction" :
                                    "Transaction par mois"}
                    </span>

                </div>),
        }, */
        /* {
            Header: 'Total',
            accessor: 'total',
        }, */
        /* {
            Header: 'SubTotal',
            accessor: 'subTotal',
        }, */

        {
            Header: 'Total',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.total.toFixed(2)} €</p>

                </div>),
        },
        {
            Header: 'Nombre de commandes',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.orders.length}</p>

                </div>),
        },
        {
            Header: 'Date de facturation',
            accessor: 'created_at',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.created_at).toLocaleString()}</p>

                </div>),
        },
        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button className='text-gray-400 hover:text-snack-pink-original mr-2' onClick={() => {
                        setInvoiceDetails(row.original)
                        setViewPDF(true);
                    }}
                    >
                        <Pdf />
                    </button>

                </div>),
        },
        {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => (
                // Use Cell to render an expander for each row.
                // We can use the getToggleRowExpandedProps prop-getter
                // to build the expander.
                <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? <Icon name="arrow_drop_up" size="xl" /> :
                        <Icon name="arrow_drop_down" size="xl" />}
                </span>
            ),
        },
    ]
    const COLUMNSORDERS = [
        {
            Header: 'Id Commande',
            accessor: 'id',
        },
        {
            Header: 'Status Commande',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <span className={`inline-flex text-xs leading-5 font-medium rounded-full px-3 py-1 text-white ${row.original.status === "new" ? 'bg-snack-pink-original ' : (row.original.status === "delivered" || row.original.status === "paid" || row.original.status === "delivered and paid") ? 'bg-snack-green-original ' : 'bg-yellow-500 '}  }   `}>
                        {row.original.status === "new" ? "Nouveau" :
                            row.original.status === "checkout" ? "En cours de finalisation" :
                                row.original.status === "paid" ? "Payé" :
                                    row.original.status === "failed" ? "Erreur" :
                                        row.original.status === "delivering" ? "En cours de livraison" :
                                            row.original.status === "delivered and paid" ? "Livrée et payée" :
                                                "Livré"}
                    </span>

                </div>),
        },
        {
            Header: 'Mode Commande',
            accessor: 'mode',
            Cell: ({ row }) => (
                <div>
                    <span className={'px-2 inline-flex text-xs leading-5 font-medium rounded-full  text-snack-gray-dark'}>
                        {row.original.mode === "COD" ? "En espèces" :
                            row.original.mode === "Card" ? "Carte Bancaire" :
                                row.original.mode === "TXN" ? "Virement Bancaire" :
                                    "Virement Mensuel"}
                    </span>

                </div>),
        },
        {
            Header: 'Total Commande',
            accessor: 'total',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.total.toFixed(2)}</p>

                </div>),
        },
        {
            Header: 'Date de livraison',
            accessor: 'shouldDeliveryAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.should_delivery_at).toLocaleString()}</p>

                </div>),
        },
        
    ]

    const getDetails = (id) => {
        axiosDash.get(`/order_items?page=1&order=${id}`)
            .then(res => {
                setInvoicesDetails(res.data);
                console.log("details :", res.data);
                setLoading2(false)
                let obj = invoices.find((item) => item.id === id)
                setTotal(obj.total)
                console.log("object :", obj);

                console.log("items", res.data);
                setShowModalDetails(true)
            })
            .catch(err => {
                console.log(err);
                setLoading2(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }
    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <SubTableCard COLUMNS={COLUMNSORDERS} DATA={row.original.orders} loading={loading} />
        ),
        [loading]
    )
    return (
        <div className="px-3 md:px-8 mt-4 flex flex-row justify-center">
            {/* {
                (invoice && !loading2) ?
                    <div style={{ position: "absolute", zIndex:22 }}>
                        <PDFViewer width="1000" height="800" className="app" >
                            <PagePDF invoice={invoice} orderDetails={ordersDetails} userDetails={userInfos.profile.first_name + ' ' + userInfos.profile.last_name} />
                        </PDFViewer>
                    </div>
                    : null
            }  */}
            <div className="container mx-auto max-w-full ">
                <TableCardSubRows onClick={() => { }} COLUMNS={COLUMNS} title="Mes factures" DATA={invoices} loading={loading} renderRowSubComponent={renderRowSubComponent} />
                <Modal size="lg" active={showModalDetails} toggler={() => setShowModalDetails(false)}>
                    <ModalHeader toggler={() => setShowModalDetails(false)}>
                        Détails commande
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <p className="text-snack-pink-original font-normal text-lg">Produits:</p>
                                    {invoicesDetails.length > 0 ? invoicesDetails.map((item, index) => (
                                        <p className="text-gray" key={index}>{item.amount + "pièce(s) - " + item.variant.title} </p>
                                    )) : <p>soon</p>}
                                    <p className="text-snack-pink-original font-normal text-lg">Total:</p>
                                    <p className="text-gray font-bold text-lg">{total + " euros"}</p>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="red"
                            buttonType="link"
                            onClick={(e) => setShowModalDetails(false)}
                            ripple="dark"
                        >
                            Fermer
                        </Button>

                    </ModalFooter>
                </Modal >




                {viewPDF ? (
                    <div className="h-screen max-h-screen">
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none"
                        >
                            <div className="relative w-full my-6 h-4/5 mx-auto overflow-y-auto max-w-3xl z-50">
                                <iframe

                                    height={"100%"}
                                    width="100%"
                                    src={`https://api.snack-time.fr/${invoiceDetails.file}`} />
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setViewPDF(false)}></div>
                        </div>

                    </div>
                ) : null}
            </div>
        </div>

    )
}
export default Invoices;