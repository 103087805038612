import { Button } from '@mui/material';
import axios1 from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getCurrentlyDilvering, getMonthlyDeliverd, getOrdersStatus, getSavedAdress, getUserContacts, getWeeklyDeliverd } from '../features/services';
import { disconnectUser, user } from '../features/userSlice';
import { Calander, HourGlass, PastClock } from './components/icons';
import { LightTooltip } from './components/Tooltips';
import Zoom from '@mui/material/Zoom';

function Home() {
  const [ordersChecked, setOrdersChecked] = useState(null)
  const [ordersDelivering, setOrdersDelivering] = useState([])
  const [ordersPaid, setOrdersPaid] = useState(null)
  const [weeklyDeliverd, setWeeklyDeliverd] = useState(null)
  const [monthlyDeliverd, setMonthlyDeliverd] = useState(null)
  const [monthlyDeliverdPercentage, setMonthlyDeliverdPercentage] = useState(null)
  const [delivringCount, setDelivringCount] = useState(null)
  const [fullContacts, setFullContacts] = useState(null)
  const [nextOrder, setNextOrder] = useState(null)
  const userInfos = useAppSelector(user);
  let navigate = useNavigate();
  useEffect(() => {
    if (userInfos.profile.id) {
      getUserContacts(userInfos.profile.id)
        .then((res) => {
          let found = 0;
          res.data.map((contact) => {
            if (contact.phone) {
              found++;
              console.log(contact.phone);
            }
          })
          if (found != 2) {
            setFullContacts(false);
          } else {
            getSavedAdress(userInfos.profile.id)
              .then(res => {
                if (!res.data[0] || !res.data[0].address.streetName) {
                  setFullContacts(false)
                } else {
                  console.log(res.data[0].address);
                  setFullContacts(true)
                }
              })
              .catch((err) => {
                console.log(err);
              })

          }
        })
        .catch((err) => {
          console.log(err);
        })
      getOrdersStatus({ status: "new", userid: userInfos.profile.id })
        .then(res => {
          let checked = res.data
          console.log(res.data)
          setOrdersChecked(checked);
        })
        .catch(err => {
          console.log(err);

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur est survenue !',
          })
        })
      getOrdersStatus({ status: "delivering", userid: userInfos.profile.id })
        .then(res => {
          let delivering = res.data
          setOrdersDelivering(delivering);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur est survenue !',
          })
        })
      getOrdersStatus({ status: "paid", userid: userInfos.profile.id })
        .then(res => {
          let paid = res.data
          setOrdersPaid(paid);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur est survenue !',
          })
        })
      getWeeklyDeliverd(userInfos.profile.id, userInfos.token)
        .then(res => {

          setWeeklyDeliverd(res.data[0])
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur est survenue !',
          })
        })
      getMonthlyDeliverd(userInfos.profile.id, userInfos.token)
        .then(res => {

          setMonthlyDeliverd(res.data.currentMonth[0].nombreCommandes)
          setMonthlyDeliverdPercentage(((res.data.currentMonth[0].total - res.data.lastMonth[0].total) / res.data.lastMonth[0].total) * 100)
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur est survenue !',
          })
        })
      getCurrentlyDilvering(userInfos.profile.id, userInfos.token)
        .then(res => {
          console.log(res.data)
          if (res.data.orders[0]) {
            setDelivringCount(res.data.orders[0].nombreCommandes)
          }
          if (res.data.nextDelivery[0]) {
            setNextOrder(res.data.nextDelivery[0].nextDelivery)
          }

        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur est survenue !',
          })
        })
    }
  }, [userInfos])

  return (
    <>
      <div className="px-3 md:px-8 mt-4">
        <div className="container mx-auto max-w-full">
          <h2 className="text-4xl px-3 font-bebas tracking-tight text-gray-800 mb-6">Dash<span className='text-snack-pink-original'>Board</span></h2>
          <div className="border-l-8 border-snack-green-original mb-4 bg-white rounded-lg shadow-lg p-3 flex justify-between ">

            <p className='font-sofiapro text-snack-gray-dark flex items-center'> <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
              <span>Veuillez complétez vos données d'entreprise</span>
            </p>
            <LightTooltip arrow TransitionComponent={Zoom} title="L'adresse, le numéro de facturation et le numéro de livraison sont nécessaires">
              <button className='focus:outline-none font-sofiapro text-snack-pink-original hover:text-pink-300' onClick={() => navigate("/parametres")}>Modifier maintenant &#10095;</button>
            </LightTooltip>
          </div>
          <div className=" mb-4">
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mt-3">
              <div className="relative w-full h-52 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg transition duration-300 ease-in-out" style={{ backgroundImage: 'url("https://image.freepik.com/free-photo/assortment-pieces-cake_114579-28210.jpg")' }}>
                <div className="absolute inset-0 bg-snack-pink-original bg-opacity-75 transition duration-300 ease-in-out" />
                <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                  <div>
                    <div className="text-white text-lg flex space-x-2 items-center">
                      <div className="bg-white rounded-md p-2 flex items-center">
                        <HourGlass fill={"#FF616D"} />
                      </div>
                      <p>Commandes en cours</p>
                    </div>
                    <h3 className="text-white text-3xl mt-2 font-bold">

                      {delivringCount != null ? delivringCount : 0}
                    </h3>
                    <h3 className="text-white text-lg mt-2">
                      commande suivante: {nextOrder ? new Date(nextOrder).toLocaleString() : "aucun pour le moment"}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="relative w-full h-52 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg transition duration-300 ease-in-out" style={{ backgroundImage: 'url("https://image.freepik.com/free-photo/traditional-italian-dessert-tiramisu-ceramic-plate_114579-9238.jpg")' }}>
                <div className="absolute inset-0 bg-yellow-600 bg-opacity-75 transition duration-300 ease-in-out" />
                <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                  <div>
                    <div className="text-white text-lg flex space-x-2 items-center">
                      <div className="bg-white rounded-md p-2 flex items-center">
                        <PastClock fill={"rgba(217, 119, 6, 1)"} />
                      </div>
                      <p>Commandes livrées cette semaine</p>
                    </div>
                    <h3 className="text-white text-3xl mt-2 font-bold">
                      {weeklyDeliverd != null ? weeklyDeliverd.nombreCommandes : "0 "}
                    </h3>
                    <h3 className="text-white text-lg mt-2  ">
                      {(weeklyDeliverd != null && weeklyDeliverd.total != null) ? weeklyDeliverd.total + "€ " : "0€"}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="relative w-full h-52 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg transition duration-300 ease-in-out" style={{ backgroundImage: 'url("https://image.freepik.com/free-photo/closeup-shot-delicious-cupcakes-with-cream-cherry-top-books_181624-42358.jpg")' }}>
                <div className="absolute inset-0 bg-blue-900 bg-opacity-75 transition duration-300 ease-in-out" />
                <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
                  <div>
                    <div className="text-white text-lg flex space-x-2 items-center">
                      <div className="bg-white rounded-md p-2 flex items-center">
                        <Calander fill={'rgba(30, 58, 138,1)'} />
                      </div>
                      <p>Total du mois</p>
                    </div>
                    <h3 className="text-white text-3xl mt-2 font-bold">
                      {monthlyDeliverd != null ? monthlyDeliverd : 0}
                    </h3>
                    <h3 className="text-white text-lg mt-2 ">
                      {monthlyDeliverdPercentage ? Math.round(monthlyDeliverdPercentage) : 0} % <span className="font-semibold text-blue-200">par rapport au mois dernier</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 className="text-4xl px-3 font-bebas tracking-tight text-gray-800 mb-3 mt-6">Commandes en cours de livraison</h2>
          <SimpleBarReact >
            <div className='w-full min-w-min overflow-x-auto'>
              <div className='w-full flex flex-row bg-gray-800 text-white p-5 rounded-lg shadow-lg mb-4'>
                <div className='md:w-1/12 w-24 min-w-min'>
                  <span>ID</span>
                </div>
                <div className='md:w-1/4 w-64 min-w-min'>
                  <span>Status</span>
                </div>
                <div className='md:w-1/6 w-64 min-w-min' >
                  <span>Mode</span>
                </div>
                <div className='md:w-1/6 w-44 min-w-min'>
                  <span>Prix</span>
                </div>
                <div className='md:w-1/6 w-44 min-w-min'>
                  <span>Prix Livraison</span>
                </div>
                <div className='md:w-1/6 w-44 min-w-min'>
                  <span>Prix Total</span>
                </div>
              </div>

              {ordersDelivering.length > 0 ? ordersDelivering.map((item, index) => (
                <div key={index} className='w-full flex flex-row bg-gray-700 text-white p-5 rounded-lg shadow-lg mb-4'>
                  <div className='md:w-1/12 w-24 min-w-min'>
                    <span>{item.id}</span>
                  </div>
                  <div className='md:w-1/4 w-64 min-w-min'>
                    <span className="bg-green-400 text-gray-50 rounded-md py-2 px-3">{item.status === "new" ? "Nouveau" :
                      item.status === "checkout" ? "En cours de finalisation" :
                        item.status === "paid" ? "Payé" :
                          item.status === "failed" ? "Erreur" :
                            item.status === "delivering" ? "En cours de livraison" :
                              "Livré"}</span>
                  </div>
                  <div className='md:w-1/6 w-64 min-w-min'>
                    <span>{item.mode === "COD" ? "En espèces" :
                      item.mode === "Card" ? "Carte Bancaire" :
                        item.mode === "TXN" ? "Transaction" :
                          "Transaction par mois"}</span>
                  </div>
                  <div className='md:w-1/6 w-44 min-w-min'>
                    <span>{item.subTotal} €</span>
                  </div>
                  <div className='md:w-1/6 w-44 min-w-min'>
                    <span>{item.deliveryFee} €</span>
                  </div>
                  <div className='md:w-1/6 w-44 min-w-min'>
                    <span>{item.total} €</span>
                  </div>
                </div>
              ))
                :
                <div className="bg-white rounded-lg shadow-lg">
                  <p className="text-center font-sofiapro text-snack-gray-dark border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 ">Aucun élement à afficher pour le moment</p>
                </div>
              }
            </div>
          </SimpleBarReact>
        </div>
      </div>
    </>
  );
}

export default Home;
