import { configureStore, ThunkAction, Action, combineReducers, applyMiddleware, createStore, createReducer } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import userReducer from '../features/userSlice';
import cartReducer from '../features/cartSlice';
import orderReducer from '../features/OrderSlice';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  order: orderReducer,
}) 

const persistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['user'] // which reducer want to store
  };

  const pReducer = persistReducer(persistConfig, rootReducer);
  //add logger as second param to the middleware to get log on console each time the state changes
  
  const store = configureStore({
  reducer: pReducer,
  // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    {
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    }),
});

export  const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;


export default store;
