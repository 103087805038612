import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Image } from '@material-tailwind/react';
import { useAppDispatch } from '../app/hooks';
import { connectUser } from '../features/userSlice';
import axios from '../features/axiosInstances/axios';
import Swal from 'sweetalert2';
import SnacktimeBanner from '../assets/images/Groupe1487.png';
import SnacktimeFooter from '../assets/images/Groupe1439.png';
import SnacktimeLogo from '../assets/images/logo-client-app.png';

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import InputIcon from '@material-tailwind/react/InputIcon';
import Button from '@material-tailwind/react/Button';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://snack-time.fr/">
        SnackTime Paris
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    let body = {
      "email": data.get('email'),
      "password": data.get('password')
    }
    axios.post('/auth/login', body)
      .then(res => {
        if (res.data.user.is_entreprise) {
          dispatch(connectUser(res.data))
          navigate('/')
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur securité est survenue !',
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur est survenue !',
        })
      })

  };

  return (
    <div className="bg-login-background overflow-hidden bg-cover bg-center w-screen h-screen relative flex flex-col justify-between" >
      <div className={'w-screen flex flex-row mb-5'}>
        <Image
          src={SnacktimeBanner}
          alt="Strawberries SnackTime"
          width="50%"

        />
        <div className='mr-20'></div>
        <Image
          src={SnacktimeBanner}
          alt="Strawberries SnackTime"
          width="50%"

        />
      </div>
      <div className="h-screen flex items-center justify-center">
        <div className="max-w-sm w-96">
          <Card>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <CardHeader color="lightBlue">
                <Image
                  src={SnacktimeLogo}
                  alt="Logo SnackTime"
                  height="auto"
                  width={180}
                />
              </CardHeader>

              <CardBody>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </CardBody>
              <CardFooter>
                <div className="flex justify-center bg-bb">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Se Connecter
                  </Button>
                </div>
              </CardFooter>
            </Box>
          </Card>
        </div>
      </div>
      <div className={'w-screen flex flex-row mt-10'}>
        <Image
          src={SnacktimeFooter}
          alt="Strawberries SnackTime"
          width="50%"

        />
        <Image
          src={SnacktimeFooter}
          alt="Strawberries SnackTime"
          width="50%"

        />
      </div>
      {/*  <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Se Connecter
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider> */}
    </div>
  );

}
