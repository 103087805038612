import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { user } from '../features/userSlice';
import TableCard from './components/TableCard';
import axios from "axios";
import Swal from "sweetalert2";
import { Eye } from './components/icons';
import { axiosDash } from '../features/axiosInstances';

function BonLivraison() {

    const userInfos = useAppSelector(user)
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)
    const [viewPDF, setViewPDF] = useState(false)
    const [BonLivraisonURL, setBonLivraisonURL] = useState(null)

    useEffect(() => {

        if (userInfos.profile.id) {

            axiosDash.get(`/orders?user=${userInfos.profile.id}`)
                .then(res => {
                    let all = res.data.reverse();
                    let tab = []
                    all.forEach(element => {
                        if (element.BonLivraison) {
                            tab.push(element)
                        }
                    });
                    setOrders(tab);
                    setLoading(false)
                    console.log("client orders", res.data);

                })
                .catch(err => {
                    setLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue !',
                    })
                })
        }

    }, [])


    const COLUMNS = [
        {
            Header: 'Commande',
            accessor: 'id',
        },
        {
            Header: 'Mode',
            accessor: 'mode',
            Cell: ({ row }) => (
                <div>
                    <span className={'px-2 inline-flex text-xs leading-5 font-medium rounded-full text-snack-gray-dark'}>
                        {row.original.mode === "COD" ? "En espèces" :
                            row.original.mode === "Card" ? "Carte Bancaire" :
                                row.original.mode === "TXN" ? "Virement Bancaire" :
                                    "Virement Mensuel"}
                    </span>

                </div>),
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.total.toFixed(2)}</p>

                </div>),
        },
        {
            Header: 'Sous Total',
            accessor: 'subTotal',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.subTotal.toFixed(2)}</p>

                </div>),
        },

        {
            Header: 'Date de création',
            accessor: 'createdAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.createdAt).toLocaleString()}</p>

                </div>),
        },
        {
            Header: 'Date de livraison',
            accessor: 'shouldDeliveryAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.shouldDeliveryAt).toLocaleString()}</p>

                </div>),
        },

        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button className='text-gray-400 hover:text-snack-pink-original mr-2' onClick={() => {
                        setBonLivraisonURL(row.original.BonLivraison)
                        setViewPDF(true);
                    }}
                    >
                        <Eye />
                    </button>
                </div>
            )
        },
    ]
    return (
        <div className="px-3 md:px-8 mt-4 flex flex-row justify-center">
            <div className="container mx-auto max-w-full ">
                <TableCard onClick={() => { }} COLUMNS={COLUMNS} title="Mes Bons de livraison" DATA={orders} loading={loading} />
            </div>
            {viewPDF ? (
                <div className="h-screen max-h-screen">
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none">
                        <div className="relative w-full my-6 h-4/5 mx-auto overflow-y-auto max-w-3xl z-50">
                            <iframe
                                height={"100%"}
                                width="100%"
                                src={`https://api.snack-time.fr/${BonLivraisonURL}`} />
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setViewPDF(false)}></div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
export default BonLivraison;