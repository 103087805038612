import React, { useEffect } from 'react';

interface DateBoxProps {
    day: {

        day: {
            name: string,
            number: number,
        },
        fullDay: Date,

    },
    selected?: {

        day: {
            name: string,
            number: number,
        },
        fullDay: Date,

    },
    index?: number,
    onPress?: () => void,

}
const DateBox = ({ day, selected, onPress }: DateBoxProps) => {

    return (
        <div onClick={onPress} className='cursor-pointer'>
            <div className={`${day.fullDay == selected.fullDay ? 'bg-snack-green-original ' : 'bg-white '} border border-snack-green-original shadow rounded flex flex-row items-center justify-center mr-3`}  >

                <div className='flex flex-row items-center justify-center w-16 h-10 font-bold'>
                    <p className={`${day.fullDay == selected.fullDay ? 'text-white ' : 'text-snack-green-original '} font-SofiaPro-600 text-base`}>{day.day.name}</p>
                    <p
                        className={`${day.fullDay == selected.fullDay ? ' text-white' : 'text-snack-green-original  '} font-SofiaPro-700 ml-1 text-base`}>{day.day.number}</p>
                </div>

            </div>
        </div>
    );
}
export default DateBox;