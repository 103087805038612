import { axiosApi, axiosDash } from "../axiosInstances";
interface getOrderStatusProps {
    status: "delivering" | "new" | "paid"
    userid: number
}

export const getOrdersStatus = ({ status, userid }: getOrderStatusProps) => {
    return axiosDash.get(`orders?status=${status}&user=${userid}`,
        { headers: { "Content-type": "application/json" } });
}
export const getWeeklyDeliverd = (userid, token) => {
    return axiosApi.get(`orders/weekly/${userid}`,
        { headers: { "Authorization": `Bearer ${token}` } });
}
export const getMonthlyDeliverd = (userid, token) => {
    return axiosApi.get(`orders/monthly/${userid}`,
        { headers: { "Authorization": `Bearer ${token}` } });
}
export const getCurrentlyDilvering = (userid, token) => {
    return axiosApi.get(`orders/current/${userid}`,
        { headers: { "Authorization": `Bearer ${token}` } });
}