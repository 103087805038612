import React, { useEffect, useState } from 'react'
import Input from "@material-tailwind/react/Input"
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addAddress, addSavedAddress, user } from '../features/userSlice';
import Swal from 'sweetalert2';
import { addAdress, addAdressDetails, getSavedAdress, updateAdress } from '../features/services';
interface addressProps {

}

function Adress() {

    const [user_adress, setUserAdress] = useState("")
    const [user_adressError, setUserAdressError] = useState("")
    const [street_name, setStreet_name] = useState("");
    const [street_nameError, setStreet_nameError] = useState("");
    const [street_number, setStreet_number] = useState("");
    const [street_numberError, setStreet_numberError] = useState("");
    const [postal_code, setPostal_code] = useState("");
    const [postal_codeError, setPostal_codeError] = useState("");
    const [province, setProvince] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [id, setId] = useState(null);
    const dispatch = useAppDispatch();

    const userInfos = useAppSelector(user)
    useEffect(() => {
        getSavedAdress(userInfos.profile.id)
            .then(res => {
                if (res.data[0]) {

                    setUserAdress(res.data[0]);
                    setStreet_name(res.data[0].address.streetName);
                    setStreet_number(res.data[0].address.streetNumber);
                    setProvince(res.data[0].address.province);
                    setPostal_code(res.data[0].address.postalCode);
                    setCity(res.data[0].address.city);
                    setId(res.data[0].address.id);
                    dispatch(addAddress(res.data[0].address.id));
                    dispatch(addSavedAddress(res.data[0].id));
                    console.log(res.data[0].id)
                }
            })
            .catch(error => {
                console.log("error ", error);
            })
    }, [])
    const enregistrerAdresse = () => {
        console.log("enregistrer");

        let adresse = {
            "streetNumber": 0,
            "streetName": street_name,
            "city": "",
            "postalCode": postal_code,
            "province": province,
            "country": "France",
            "location": {
            },
            "user": { "id": userInfos.profile.id }
        }

        addAdressDetails(adresse)
            .then(res => {
                dispatch(addAddress(res.data.id));

                let savedAdresse = {
                    "label": "default",
                    "isDefault": true,
                    "createdAt": new Date(),
                    "updatedAt": new Date(),
                    "address": { "id": res.data.id },
                    "user": { "id": userInfos.profile.id }
                }
                addAdress(savedAdresse)
                    .then(res => {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: 'Adresse ajoutée avec succès',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(error => {
                        console.log("err2", error.response);
                    })

            })
            .catch(error => {
                console.log("error ", error.response);

            })
    }
    const modifierAdresse = () => {
        console.log("modifier");
        let Adresse = {
            "streetNumber": 0,
            "streetName": street_name,
            "city": city,
            "postalCode": postal_code,
            "province": "",
        }
        updateAdress(id, Adresse)
            .then(res => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Adresse modifiée avec succès',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(error => {
                console.log("err2", error.response);
            })
    }
    return (
        <div className='container mx-auto' style={{ height: 300, width: '100%', }}>
            <div className='mt-10'>
                <h3 className="font-semibold text-gray-600 text-xs uppercase my-2 w-2/5">Details Adresse</h3>

                <div className='my-5 flex flex-row '>
                    <div className='w-full mx-2 '>
                        <Input
                            type="text"
                            size="medium"
                            color="lightBlue"
                            outline={true}
                            placeholder="Adresse"
                            value={street_name ? street_name : ''}
                            error={cityError}
                            onBlur={() => {
                                if (street_name === "") {
                                    setCityError("Adresse obligatoire")
                                }
                            }}
                            onChange={(e) => setStreet_name(e.target.value)}
                        />

                    </div>

                </div>

                <div className='my-5 flex flex-row '>
                    <div className='w-1/2 mx-2'>
                        <Input
                            type="text"
                            color="lightBlue"
                            size="medium"
                            outline={true}
                            placeholder="Code postal"
                            value={postal_code ? postal_code : ""}
                            error={postal_codeError}
                            onBlur={() => {
                                if (postal_code === "") {
                                    setPostal_codeError("Code postal obligatoire")
                                }
                            }}
                            onChange={(e) => setPostal_code(e.target.value)}
                        />
                    </div>
                    <div className='w-1/2 mx-2 '>
                        <Input
                            type="text"
                            color="lightBlue"
                            size="medium"
                            outline={true}
                            placeholder="Ville"
                            value={city ? city : ""}
                            error={cityError}
                            onBlur={() => {
                                if (city === "") {
                                    setCityError("Ville obligatoire")
                                }
                            }}
                            onChange={(e) => setCity(e.target.value)}
                        />

                    </div>

                </div>

                <div className="mt-5 flex justify-end">
                    <button className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                        onClick={() => { user_adress ? modifierAdresse() : enregistrerAdresse() }}>
                        Enregistrer
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Adress;