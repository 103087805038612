import { Box, Checkbox, Fade, Input, TextField } from '@mui/material'
import axios from '../features/axiosInstances/axios';
import { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { user } from '../features/userSlice';
import { setCart } from '../features/cartSlice';
import Select from 'react-select'
import { getAllIngredientsWithPrice, getPotsRestaurants } from '../features/services';
import ListMenuItem from './components/ListMenuItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { axiosApi, axiosDash } from '../features/axiosInstances';
import { Modal, ModalBody, ModalFooter, ModalHeader, InputIcon } from '@material-tailwind/react';
import CustomListMenuItem from './components/CustomListMenuItem';
import CustomDessertIngredients from './components/CustomDessertIngredients';
import { addCartItemToppingsAmount } from '../features/services/cart.services';

function ListMenu() {
    const userInfos = useAppSelector(user)
    const dispatch = useAppDispatch();

    const [variants, setVariants] = useState(null)
    const [customvariants, setCustomVariants] = useState(null)
    const [variantsFiltered, setVariantsFiltered] = useState(null)
    const [dessertTypeOptions, setDessertTypeOptions] = useState(null)
    const [choice, setChoice] = useState(null)
    const [loading, setLoading] = useState(true)
    const [pots, setPots] = useState([])
    const [loadingpots, setLoadingpots] = useState(true)
    const [selectedPot, setSelectedPot] = useState(null)
    const [showPots, setShowPots] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedCustom, setSelectedCustom] = useState(null);
    const [ToppingsOptions, setToppingsOptions] = useState([])
    const [SaucesOptions, setSaucesOptions] = useState([])
    const [sauceOption, setSauceOption] = useState(null)
    const [toppingOption, setToppingOption] = useState(null)
    const [count, setCount] = useState(1)
    const [variantname, setVariantName] = useState("")



    useEffect(() => {
        getPotsRestaurants(userInfos.profile.id)
            .then((res) => {
                setLoadingpots(false)
                setPots(res.data);
                setSelectedPot(res.data[0])
            })
            .catch((err) => {
                setLoadingpots(false)
            })

        getVariants()
        axios.get('/desserts')
            .then(res => {
                console.log(res.data)
                let t = [];
                var promises = res.data.map((item) => {
                    if (item.id !== 15 && item.id !== 16 && item.id !== 13) {
                        t.push({ label: item.label, value: item.id })
                    }
                })
                Promise.all(promises)
                    .then(function (results) {
                        setDessertTypeOptions(t);
                    })

            })
            .catch(err => {
                console.log(err.response);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })

        getAllIngredientsWithPrice()
            .then(res => {
                let tabT = []
                let tabS = []
                res.data.map((item) => {
                    if (item.type == "sauces et purees") {
                        tabS.push({ label: item.label, value: item.id, parent: item.type })
                    } else {
                        tabT.push({ label: item.label, value: item.id, parent: item.type })
                    }
                })
                setToppingsOptions(tabT);
                setSaucesOptions(tabS);
            })
            .catch(err => {
                console.log("err in ings");
            })
    }, [])

    useEffect(() => {
        setSelectedCustom(null);
        setCount(1)

    }, [showModal])

    const filterData = (option) => {
        let filtered = [];
        if (option !== null) {
            if (option.value === 13) {
                setShowPots(false)
            } else {
                setShowPots(true)
            }
            var promises = variants.map((item) => {
                if (item.dessert.label === option.label)
                    filtered.push(item);
            })
            Promise.all(promises)
                .then(function (results) {
                    setLoading(false);
                    setVariantsFiltered(filtered);
                })
        }
        else {
            setShowPots(true)
            setVariantsFiltered(variants);
        }

    }

    const getVariants = () => {

        axiosDash.get('/variants?order%5Btitle%5D=asc')
            .then(res => {
                console.log(res.data)
                let precomposed = [];
                let customDesserts = [];
                res.data.map((item) => {
                    if (item.dessert.id != 15 && item.dessert.id != 16 && item.dessert.id != 13) {
                        if (!item.isCustomizable) {
                            precomposed.push(item)
                        } else {
                            /*if not tiramissu individuel (appatient à la 
                            catégorie mini gourmandises qui n'est pas accessible au restaurants)*/
                            if (item.id != 73) {
                                customDesserts.push(item)
                            }
                        }
                    }
                })

                setVariants(precomposed)
                setCustomVariants(customDesserts)
                setVariantsFiltered(precomposed);
                setLoading(false);

            })
            .catch(err => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }

    const customAddToCart = () => {
        addCartItemToppingsAmount(selectedCustom.id, [{ "id": sauceOption.value, "amount": 1 },
        { "id": toppingOption.value, "amount": 1 }], count, selectedPot.id, userInfos.token)
            /* addCartItem(userInfos.token, variant.id, count)*/
            .then(res => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Dessert ajouté au panier!',
                    showConfirmButton: false,
                    timer: 1500
                })
                setShowModal(false)
                setToppingOption(null);
                setSauceOption(null);
                axiosApi.get("/cart", {
                    headers: { "Authorization": `Bearer ${userInfos.token}` }
                })
                    .then(res => {
                        if (res.data) {
                            dispatch(setCart(res.data))
                        }
                    })
                    .catch(err => {
                        console.log(err.response);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Une erreur est survenue !',
                        })
                    })
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }
    return (
        <div className="max-w-2xl mx-auto px-2 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8 mt-4">
            <div className="flex flex-col md:flex-row justify-between">
                <h2 className="text-4xl px-3 font-bebas tracking-tight text-snack-gray-dark">Liste <span className='text-snack-pink-original'>des produits</span> précomposés</h2>
                <Select className="md:w-1/4 w-full z-20"
                    options={dessertTypeOptions}
                    isClearable
                    onChange={selectedOption => {
                        setChoice(selectedOption);
                        filterData(selectedOption);
                    }}
                    value={choice}
                    placeholder="Famille de dessert"
                />
            </div>
            {showPots ?
                <div>
                    <h2 className="text-lg px-3 font-sofiapro tracking-tight text-gray-800">Veuillez choisir un <span className='text-snack-pink-original'>pot</span> </h2>
                    <div className={"flex flex-row flex-wrap items-center justify-center"}>

                        {pots && !loadingpots ?

                            pots.map((item, index) => (
                                <div key={index} className='md:w-1/5 w-full  py-2'>
                                    <div onClick={() => {
                                        setSelectedPot(item)
                                    }} className={`border-4 ${selectedPot === item ? "border-snack-green-original" : ""} cursor-pointer relative h-0 pb-full rounded-md overflow-hidden group-hover:opacity-75  bg-snack-pink-light`}>
                                        <img src={item.pot.cover.server + "/" + item.pot.cover.filename} alt="tiramissu-snack" className='absolute inset-0 w-full h-full object-cover' />
                                        <div className='absolute bottom-0 w-full h-1/4 flex justify-center items-center text-center bg-white bg-opacity-50'>
                                            {selectedPot ? <Checkbox
                                                checked={
                                                    selectedPot.id === item.id ?
                                                        true
                                                        :
                                                        false
                                                }
                                                icon={<RadioButtonUncheckedIcon fontSize="large" style={{ fill: "#5EC394" }} />}
                                                checkedIcon={<CheckCircleIcon fontSize="large" style={{ fill: "#5EC394" }} />}
                                            />
                                                : null}
                                            <p className='text-snack-gray-dark font-sofiapro'>{item.pot.material ? " " + item.pot.material : null} {item.price ? " à €" + item.price + " " : null} </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <div className="mt-6 flex w-full justify-center gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                <Loader
                                    type="ThreeDots"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        }
                    </div>

                </div> : null}
            <div className={"w-full py-2 flex flex-col justify-center items-center"}>
                <div className="w-1/2 text-center">
                    <h2 className="text-lg py-2 font-sofiapro tracking-tight text-gray-800">Choisissez votre préféré parmi nos
                        <span className='text-snack-pink-original'> délicieux desserts</span> ci-dessous,
                        ou mieux encore, <span className='text-snack-pink-original'>personnalisez</span> votre propre dessert à vos goûts !</h2>
                </div>
                <div className="w-1/4">
                    <button className={` w-full bg-snack-pink-original hover:opacity-80  cursor-pointer  rounded font-semibold  py-3 text-sm text-white uppercase`}
                        onClick={() => {
                            setShowModal(true);
                        }}>Personnalisez</button>
                </div>
            </div>
            <div className={"w-full py-4 flex flex-col md:flex-row justify-between items-start"}>

                <div className="align-middle inline-block min-w-full ">
                    <div className="shadow overflow-hidden border-b  border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y md:bg-white divide-gray-200">
                            <thead className="bg-gray-50  ">
                                <tr>
                                    <th className="px-6 py-3 w-2/6 text-center text-sm font-normal tracking-wider">
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <SearchRoundedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                            <TextField value={variantname} label="Dessert" color="success"
                                                variant="standard" onChange={(e) => { setVariantName(e.target.value) }} />
                                        </Box>
                                    </th>
                                    <th className="px-6 py-4  w-1/6 text-left text-sm font-normal text-gray-500 uppercase tracking-wider">Famille</th>
                                    <th className="px-6 py-4  w-1/6 text-center text-sm font-normal text-gray-500 uppercase tracking-wider">Quantité</th>
                                    <th className="px-6 py-4  w-2/6 text-center text-sm font-normal text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">

                                {variantsFiltered && !loading ?
                                    variantsFiltered.map((item, index) => (
                                        (item.title.toLowerCase().includes(variantname)) ?
                                            <ListMenuItem item={item} key={index} selectedPot={selectedPot} />
                                            : null
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={5} className="py-6 text-center">
                                            <div className="font-sofiapro">
                                                Aucun élement à afficher
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <Modal size="lg" active={showModal} toggler={() => {
                setToppingOption(null);
                setSauceOption(null); setShowModal(false);
            }}>
                <ModalHeader toggler={() => {
                    setToppingOption(null);
                    setSauceOption(null);
                    setShowModal(false);
                }}>
                    Personnalisez votre dessert
                </ModalHeader>
                <ModalBody>

                    <div className="flex flex-col ">
                        <table className="flex w-full divide-y ">
                            <tbody className="w-96 ">
                                {customvariants && !loading && selectedPot ?
                                    customvariants.map((item, index) => (
                                        (selectedPot.pot.id == 13 && item.dessert.id == 12) || (selectedPot.pot.id == 12) ? (
                                            <CustomListMenuItem item={item} key={index} selectedPot={selectedPot}
                                                onClick={() => { setSelectedCustom(item) }} selected={selectedCustom} />
                                        ) : null
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={5} className="py-6 text-center">
                                            <div className="font-sofiapro">
                                                Aucun élement à afficher
                                            </div>
                                        </td>
                                    </tr>

                                }
                            </tbody>
                        </table>
                        {selectedCustom ?
                            <Fade in={selectedCustom}>
                                <div>
                                    <div className="w-full ">
                                        <h3 className=" py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Un topping</h3>
                                        <CustomDessertIngredients toppings={ToppingsOptions} onChange={(selectedOption) => {
                                            setToppingOption(selectedOption);
                                            console.log(selectedOption)
                                        }} selected={toppingOption} />
                                    </div>
                                    <div className="w-full">
                                        <h3 className=" py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Une sauce</h3>
                                        <Select className="w-full"
                                            isClearable
                                            value={sauceOption}
                                            options={SaucesOptions}
                                            placeholder="Selectionnez ..."
                                            onChange={selectedOption => {
                                                setSauceOption(selectedOption);
                                                console.log(selectedOption)
                                            }}
                                        />
                                    </div>
                                    <div className="w-full mt-5 ">
                                        <div className="flex justify-center shadow-md bg-snack-gray-light rounded-3xl h-1/3 px-3">
                                            <svg onClick={() => { if (count > 1) setCount(count - 1) }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                                <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                            </svg>
                                            <div className='flex justify-center items-center'>
                                                <input className="mx-2 text-center w-8 bg-transparent border-0 focus:outline-none" type="text"
                                                    onChange={(e) => { if (e.target.value) { setCount(parseInt(e.target.value)) } else setCount(1) }} value={count} />
                                            </div>
                                            <svg onClick={() => { setCount(count + 1) }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </Fade> : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex flex-row justify-end w-full">

                        <button className={`  px-4 mr-4 bg-snack-pink-original hover:opacity-80  cursor-pointer  rounded font-semibold  py-3 text-sm text-white uppercase`}
                            onClick={() => {
                                setSelectedCustom(null);
                                setShowModal(false)
                            }}>Fermer</button>
                        <button className={`  px-4 bg-snack-green-original hover:opacity-80  cursor-pointer  rounded font-semibold  py-3 text-sm text-white uppercase`}
                            onClick={() => {
                                if (toppingOption || sauceOption) {
                                    customAddToCart()
                                } else {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Oops...',
                                        text: 'Veuillez finaliser le processus de personnalisation de dessert avant de procéder!',
                                    })
                                }
                            }}>Enregistrer</button>

                    </div>
                </ModalFooter>
            </Modal>
        </div >
    )
}
export default ListMenu;
