import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../app/hooks";
import { user } from "../features/userSlice";
import TableCard from './components/TableCard';
import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from '@material-tailwind/react';
import { PDFViewer } from "@react-pdf/renderer";
import PagePDF from "./components/pdf/InvoicePDF";
import '../assets/css/orders.css'
import { Eye, Pdf } from "./components/icons";
import { getAllIngredientsWithPrice } from "../features/services";
import OrderDetails from "./components/OrderDetails";
import { axiosDash } from "../features/axiosInstances";

function ClientOrders() {
    const [showModalDetails, setShowModalDetails] = useState(false);

    const userInfos = useAppSelector(user)
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [viewPDF, setViewPDF] = useState(false)
    const [ordersDetails, setOrdersDetails] = useState([])
    const [total, setTotal] = useState(0)
    const [invoice, setInvoice] = useState({})
    const [detailsIngredients, SetDetailsIngredients] = useState(null)

    useEffect(() => {

        if (userInfos.profile.id) {

            axiosDash.get(`/orders?user=${userInfos.profile.id}`)
                .then(res => {
                    let all = res.data.reverse();
                    setOrders(all);
                    setLoading(false)
                    console.log("client orders", res.data);

                })
                .catch(err => {
                    setLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue !',
                    })
                })
        }
        getAllIngredientsWithPrice()
            .then(resIngs => {
                SetDetailsIngredients(resIngs.data);
            })
            .catch(err => {
                console.log("err in ings", err);
            })
    }, [])


    const COLUMNS = [
        {
            Header: 'Id',
            accessor: 'id',
        },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <span className={`inline-flex text-xs leading-5 font-medium rounded-full px-3 py-1 text-white ${row.original.status === "new" ? 'bg-snack-pink-original ' : (row.original.status === "delivered" || row.original.status === "paid" || row.original.status === "delivered and paid") ? 'bg-snack-green-original ' : 'bg-yellow-500 '}  }  `}>
                        {row.original.status === "new" ? "Nouveau" :
                            row.original.status === "checkout" ? "En cours de finalisation" :
                                row.original.status === "paid" ? "Payée" :
                                    row.original.status === "failed" ? "Erreur" :
                                        row.original.status === "delivering" ? "En cours de livraison" :
                                            row.original.status === "delivered and paid" ? "Livrée et payée" :
                                                "Livrée"}
                    </span>

                </div>),
        },
        {
            Header: 'Mode',
            accessor: 'mode',
            Cell: ({ row }) => (
                <div>
                    <span className={'px-2 inline-flex text-xs leading-5 font-medium rounded-full text-snack-gray-dark'}>
                        {row.original.mode === "COD" ? "En espèces" :
                            row.original.mode === "Card" ? "Carte Bancaire" :
                                row.original.mode === "TXN" ? "Virement Bancaire" :
                                    "Virement Mensuel"}
                    </span>

                </div>),
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.total.toFixed(2)}</p>

                </div>),
        },
        {
            Header: 'Sous Total',
            accessor: 'subTotal',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{row.original.subTotal.toFixed(2)}</p>

                </div>),
        },

        {
            Header: 'Date de livraison',
            accessor: 'shouldDeliveryAt',
            Cell: ({ row }) => (
                <div>
                    <p className="text-gray">{new Date(row.original.shouldDeliveryAt).toLocaleString()}</p>

                </div>),
        },

        {
            Header: 'Actions',
            accessor: '[editButton]',
            Cell: ({ row }) => (
                <div>
                    <button className='text-gray-400 hover:text-snack-pink-original mr-2' onClick={() => {
                        getDetails(row.original.id)
                    }}
                    >
                        <Eye />
                    </button>
                    {/*    <button className='text-gray-400 hover:text-snack-pink-original mr-2' onClick={() => {

                        axios.get(`https://api-dashboard.snack-time.fr/api/order_items?page=1&order=${row.original.id}`)
                            .then(res => {
                                setOrdersDetails(res.data);
                                console.log("details :", res.data);
                                setLoading2(false)
                                let obj = orders.find((item) => item.id === row.original.id)
                                setTotal(obj.total)
                                console.log("object :", obj);

                                console.log("items", res.data);
                            })
                            .catch(err => {
                                console.log(err);
                                setLoading2(false)
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Une erreur est survenue !',
                                })
                            })

                        setInvoice(row.original)
                        setViewPDF(true);
                        console.log(row.original);

                    }}
                    >
                        <Pdf />
                    </button> */}
                </div>),
        }
    ]

    const getDetails = (id) => {
        axiosDash.get(`/order_items?&order=${id}`)
            .then(res => {
                setOrdersDetails(res.data);
                setLoading2(false)
                let obj = orders.find((item) => item.id === id)
                setTotal(obj.total)


            })
            .catch(err => {
                console.log(err);
                setLoading2(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }
    useEffect(() => {
        if (detailsIngredients != undefined && detailsIngredients.length > 0) {
            setShowModalDetails(true)
        }
    }, [ordersDetails]);


    return (

        <div className="px-3 md:px-8 mt-4 flex flex-row justify-center">



            {/* {
                (invoice && !loading2) ?
                    <div style={{ position: "absolute", zIndex:22 }}>
                        <PDFViewer width="1000" height="800" className="app" >
                            <PagePDF invoice={invoice} orderDetails={ordersDetails} userDetails={userInfos.profile.first_name + ' ' + userInfos.profile.last_name} />
                        </PDFViewer>
                    </div>
                    : null

            }  */}

            <div className="container mx-auto max-w-full ">

                <TableCard onClick={() => { }} COLUMNS={COLUMNS} title="Mes commandes" DATA={orders} loading={loading} />
                <Modal size="lg" active={showModalDetails} toggler={() => setShowModalDetails(false)}>
                    <ModalHeader toggler={() => setShowModalDetails(false)}>
                        Détails commande
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="md:grid md:grid-cols-12 md:gap-12">
                                <div className="md:col-span-12">
                                    <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr >
                                                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-2/5">Produit</th>
                                                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-1/5">Quantité</th>
                                                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider w-2/5"> Ingrédients</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">

                                                        {(ordersDetails.length > 0) ? ordersDetails.map((item, index) => (
                                                            <OrderDetails item={item} detailsIngredients={detailsIngredients} key={index} />
                                                        ))
                                                            :
                                                            <tr className="mt-6 flex w-full items-end justify-center gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                                                <td>Aucun élement à afficher</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" flex w-full justify-end">
                                        <p className=" font-medium text-base">Total : </p>
                                        <p className="text-snack-pink-original font-medium text-base">{" " + total.toFixed(2) + "€"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <button className={`  px-4 bg-snack-pink-original hover:opacity-80  cursor-pointer  rounded font-semibold  py-3 text-sm text-white uppercase`}
                            onClick={() => {
                                setShowModalDetails(false)
                            }}>Fermer</button>

                    </ModalFooter>
                </Modal >

                {viewPDF ? (
                    <div className="h-screen max-h-screen">
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none"
                        >
                            <div className="relative w-4/5 my-6 h-4/5 mx-auto overflow-y-auto max-w-3xl z-50">
                                {/*content*/}
                                {
                                    (invoice && !loading2) ?
                                        <PDFViewer width="100%" height="100%" className="app" >
                                            <PagePDF invoice={invoice} orderDetails={ordersDetails} userDetails={userInfos.profile.first_name + ' ' + userInfos.profile.last_name} />
                                        </PDFViewer>
                                        : null

                                }
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setViewPDF(false)}></div>
                        </div>

                    </div>
                ) : null}
            </div>
        </div>

    )
}
export default ClientOrders;