import { axiosDash, axiosApi } from '../axiosInstances'
//saved_addresses?user=${userInfos.profile.id}

export const getSavedAdress = (id) => {
    return axiosDash.get(`/saved_addresses?user=${id}`);
}
export const addAdressDetails = (data) => {
    return axiosDash.post(`/addresses`, data);
}
export const addAdress = (data) => {
    return axiosDash.post(`/saved_addresses `, data);
}
export const updateAdress = (id, data) => {
    return axiosDash.patch(`/addresses/${id}`, data,
        { headers: { "content-type": "application/merge-patch+json" } });
}
export const getUserAdr = (adr) => {
    return axiosDash.get(`/addresses/${adr}`);
}