import { axiosApi, axiosDash } from "../axiosInstances";


export const getAllPots = (/* token */) => {
    return axiosApi.get("/pots", /* {
        headers: { "Authorization": `Bearer ${token}` }
    } */);
}
export const getPotsRestaurants = (id:number) => {
    return axiosDash.get(`/prix_pots_entreprises?entreprise=${id}`);
}
export const getDessertPots = (/* token */ id) => {
    return axiosApi.get(`/pots/dessert/${id}`, /* {
        headers: { "Authorization": `Bearer ${token}` }
    } */);
}
