import { useMemo } from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { Button, Icon, InputIcon } from '@material-tailwind/react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import Loading from './Loading';

const SubTableCard = ({ COLUMNS, DATA, loading }) => {
    const columns = useMemo(() => COLUMNS, [DATA])
    const data = useMemo(() => DATA, [DATA])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        headers,
        prepareRow,
    } = useTable(
        {
            columns,
            data,

        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    return (
        <div className="overflow-x-auto">
            <table {...getTableProps()} className="items-center w-full bg-gray-100 border-collapse">
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                            {headerGroup.headers.map((column, index) => (
                                <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())} className="px-2 text-snack-gray-dark align-middle border-b border-solid border-gray-300 py-3 text-sm whitespace-nowrap font-medium text-left">
                                    {column.render('Header')}
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? <Icon name="arrow_drop_down" size="xl" />
                                            : <Icon name="arrow_drop_up" size="xl" />
                                        : ''}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {loading ?
                        <Loading rows={headers.length} />
                        :
                        page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr key={index} {...row.getRowProps()} >
                                    {row.cells.map((cell, index) => {
                                        return <th key={index} {...cell.getCellProps()} className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left" >{cell.render('Cell')}</th>
                                    })}
                                </tr>
                            )
                        })

                    }

                </tbody>
            </table>
        </div>
    );
}
export default SubTableCard