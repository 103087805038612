import axios from "../features/axiosInstances/axios";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { updateUser, user } from "../features/userSlice";
import Swal from "sweetalert2";
import PasswordUpdate from "./components/PasswordUpdate";
import PhoneUpdate from "./components/PhoneUpdate";
import { Input } from "@mui/material";

function Compte() {
    const userInfos = useAppSelector(user)
    const [firstName, setFirstName] = useState(userInfos.profile.first_name ? userInfos.profile.first_name : "")
    const [lastName, setLastName] = useState(userInfos.profile.last_name ? userInfos.profile.last_name : "")
    const [email, setEmail] = useState(userInfos.profile.email ? userInfos.profile.email : "")
    const [phone, setPhone] = useState(userInfos.profile.phone_number ? userInfos.profile.phone_number : "")
    const [modalPhone, setModalPhone] = useState(false);
    const [modalPass, setModalPass] = useState(false);


    const dispatch = useAppDispatch();

    const updateProfile = () => {
        let updateBody = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            //"phone_number": phone,
        }

        axios.patch('/me', updateBody, {
            headers: { "Authorization": `Bearer ${userInfos.token}` }
        })
            .then(res => {
                dispatch(updateUser(updateBody))
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Informations modifiées!',
                    showConfirmButton: false,
                    timer: 1500
                })

            })
            .catch(error => {
                console.log("error ", error.response);

            })
    }
    return (
        <>
            <div className=" pt-6 md:ml-8 py-6 px-4 ">
                <div className="bg-white md:rounded-xl h-screen rounded-r-lg shadow-xl flex-wrap ">
                    <div className="max-w-2xl mx-auto px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-800">Profile</h2>
                        <div className=" my-10 flex flex-row justify-evenly">
                            <p className="w-1/2 text-gray-500">Nom</p>
                            <div className=" w-1/2">
                                <Input type="text" placeholder="Nom" value={lastName} onChange={(e) => { if (e.target.value.match("^^[_A-z0-9]*((-|\s)*[_A-z0-9])*$") !== null) setLastName(e.target.value); }}
                                    className="placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0  outline-none focus:outline-none focus:ring-0" />
                                {lastName.length < 1 && <p className="text-snack-pink-original font-normal text-sm">Nom obligatoire</p>}
                            </div>
                        </div>
                        <div className="  my-10 flex flex-row justify-evenly">
                            <p className="w-1/2 text-gray-500">Prénom</p>
                            <div className=" w-1/2">
                                <Input type="text" placeholder="Prénom" value={firstName} onChange={(e) => { if (e.target.value.match("^^[_A-z0-9]*((-|\s)*[_A-z0-9])*$") !== null) setFirstName(e.target.value) }}
                                    className="placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0  outline-none focus:outline-none focus:ring-0" />
                                {firstName.length < 1 && <p className="text-snack-pink-original font-normal text-sm">Prénom obligatoire</p>}
                            </div>
                        </div>
                        <div className=" my-10 flex flex-row justify-evenly">
                            <p className="w-1/2 text-gray-500">Email</p>
                            <div className="w-1/2">
                                <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}
                                    className="placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0  outline-none focus:outline-none focus:ring-0" />
                                {email.length < 1 && <p className="text-snack-pink-original font-normal text-sm">E-mail obligatoire</p>}
                            </div>
                        </div>
                        <div className=" my-10 flex flex-row justify-evenly">
                            <p className="w-1/2 text-gray-500">Numéro de téléphone</p>
                            <div className="w-1/2 flex flex-row justify-between">
                                <p className=" text-gray-500">{phone}</p>
                                <button className="text-pink-500 bg-transparent hover:text-pink-400 active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-5 mb-1 ease-linear transition-all duration-150" type="button"
                                    onClick={() => setModalPhone(true)}>
                                    Changer
                                </button>
                            </div>
                        </div>
                        <div className=" my-10 flex flex-row justify-evenly">
                            <p className="w-1/2 text-gray-500">Mot de passe</p>
                            <div className="w-1/2 flex flex-row justify-between">
                                <p className="text-gray-500">******</p>
                                <button className="text-pink-500 bg-transparent hover:text-pink-400 active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-5 mb-1 ease-linear transition-all duration-150" type="button"
                                    onClick={() => setModalPass(true)}>
                                    Changer
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                                onClick={updateProfile}>
                                Enregistrer
                            </button>
                        </div>
                        <PasswordUpdate modalVisible={modalPass} closeMod={() => { setModalPass(false) }} />
                        <PhoneUpdate modalVisible={modalPhone} closeMod={() => { setModalPhone(false) }} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Compte;