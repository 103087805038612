import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDeliveryDateHour } from "../../features/OrderSlice";
import DateBox from "./DateBox";
import HoursBox from "./HoursBox";
import SimpleBarReact from 'simplebar-react';

interface formattedDates {
    day: {
        name: string,
        number: number,
    },
    fullDay: Date,
}

function OrderHours() {

    const dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const timeRange = [
        {
            time: {
                hours: 9,
                mins: 0
            }
        },
        {
            time: {
                hours: 9,
                mins: 20
            }
        },
        {
            time: {
                hours: 9,
                mins: 40
            }
        },
        {
            time: {
                hours: 10,
                mins: 0
            }
        },
        {
            time: {
                hours: 10,
                mins: 20
            }
        },
        {
            time: {
                hours: 10,
                mins: 40
            }
        },
        {
            time: {
                hours: 11,
                mins: 0
            }
        },
        {
            time: {
                hours: 11,
                mins: 20
            }
        },
        {
            time: {
                hours: 11,
                mins: 40
            }
        },
        {
            time: {
                hours: 12,
                mins: 0
            }
        },
        {
            time: {
                hours: 12,
                mins: 20
            }
        },
        {
            time: {
                hours: 12,
                mins: 40
            }
        },
        {
            time: {
                hours: 13,
                mins: 0
            }
        },
        {
            time: {
                hours: 13,
                mins: 20
            }
        },
        {
            time: {
                hours: 13,
                mins: 40
            }
        },
        {
            time: {
                hours: 14,
                mins: 0
            }
        },
        {
            time: {
                hours: 14,
                mins: 20
            }
        },
        {
            time: {
                hours: 14,
                mins: 40
            }
        },
        {
            time: {
                hours: 15,
                mins: 0
            }
        },
        {
            time: {
                hours: 15,
                mins: 20
            }
        },
        {
            time: {
                hours: 15,
                mins: 40
            }
        },
        {
            time: {
                hours: 16,
                mins: 0
            }
        },
        {
            time: {
                hours: 16,
                mins: 20
            }
        },
        {
            time: {
                hours: 16,
                mins: 40
            }
        },
        {
            time: {
                hours: 17,
                mins: 0
            }
        },
        {
            time: {
                hours: 17,
                mins: 20
            }
        },
        {
            time: {
                hours: 17,
                mins: 40
            }
        },
        {
            time: {
                hours: 18,
                mins: 0
            }
        },
        {
            time: {
                hours: 18,
                mins: 20
            }
        },
        {
            time: {
                hours: 18,
                mins: 40
            }
        },

        {
            time: {
                hours: 19,
                mins: 0
            }
        },
        {
            time: {
                hours: 19,
                mins: 20
            }
        },
        {
            time: {
                hours: 19,
                mins: 40
            }
        },
        {
            time: {
                hours: 20,
                mins: 0
            }
        },
        {
            time: {
                hours: 20,
                mins: 20
            }
        },
        {
            time: {
                hours: 20,
                mins: 40
            }
        },
        {
            time: {
                hours: 21,
                mins: 0
            }
        },
        {
            time: {
                hours: 21,
                mins: 20
            }
        },

        {
            time: {
                hours: 21,
                mins: 40
            }
        },
        {
            time: {
                hours: 22,
                mins: 0
            }
        },
        {
            time: {
                hours: 22,
                mins: 20
            }
        },
        {
            time: {
                hours: 22,
                mins: 40
            }
        },
        {
            time: {
                hours: 23,
                mins: 0
            }
        },
        {
            time: {
                hours: 23,
                mins: 20
            }
        },
        {
            time: {
                hours: 23,
                mins: 40
            }
        },
        {
            time: {
                hours: 0,
                mins: 0
            }
        },
    ]

    const [today, SetToday] = useState(new Date());
    const [selectedDay, SetSelectedDay] = useState<formattedDates>();
    const [selectedTime, SetSelectedTime] = useState(timeRange[0]);
    const [daysofmonth, SetDaysOfMonth] = useState([]);
    const [DeliveryDate, setDeliveryDate] = useState(null)
    const [hours, setHours] = useState([]);
    const dispatch = useAppDispatch();



    //show 7 days of the week no matter what
    const getDaysArray = async (myFutDate: Date) => {
        let arr = [];
        for (let index = 1; index < 8; index++) {
            /* if (myFutDate.getDay() == 5) {
                myFutDate.setDate(myFutDate.getDate() + 3);
              } else { */
            arr.push(new Date(myFutDate));
            myFutDate.setDate(myFutDate.getDate() + 1);
            // }
            //myFutDate.setDate(myFutDate.getDate() + index);

        }
        /* for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));*/
        return arr;
    };
    //let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    //let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    useEffect(() => {
        const formatDates = async () => {

            let allDays = await getDaysArray(today);
            SetSelectedDay({ day: { name: dayNames[today.getDay()], number: today.getDate() }, fullDay: allDays[0] })


            let T: Array<formattedDates> = [];
            for (let index = 0; index < allDays.length; index++) {
                let name = dayNames[allDays[index].getDay()]
                let number = allDays[index].getDate()
                T.push({ day: { name: name, number: number }, fullDay: allDays[index] })
            }
            SetDaysOfMonth(T);
            return daysofmonth;
        };
        formatDates();

    }, [today])

    useEffect(() => {

        if (selectedDay !== undefined && selectedTime !== undefined) {
            
            const d = selectedDay.fullDay;
            d.setHours(selectedTime.time.hours);
            d.setMinutes(selectedTime.time.mins);
            const v = selectedDay.fullDay.toString()
            setDeliveryDate(selectedDay.fullDay)
            dispatch(setDeliveryDateHour(v))
        }


    }, [selectedDay, selectedTime])

    useEffect(() => {
        if (selectedDay)
            generateHours()
    }, [selectedDay]);


    const generateHours = () => {
        let hourslist = [];
        let initialIndex = 9;
        if (today.getDay() == selectedDay.fullDay.getDay()) {
            initialIndex = today.getHours();
        }
        for (let i = initialIndex; i < 24; i++) {

            if ((today.getDay() == selectedDay.fullDay.getDay()) && (i == today.getHours())) {

                if (today.getMinutes() < 20) {
                    hourslist.push({
                        time: {
                            hours: i,
                            mins: 20
                        }
                    }, {
                        time: {
                            hours: i,
                            mins: 40
                        }
                    })

                } else if (today.getMinutes() > 20 && today.getMinutes() < 40) {
                    hourslist.push({
                        time: {
                            hours: i,
                            mins: 40
                        }
                    })
                }
            } else {
                for (let j = 0; j <= 40; j += 20) {
                    hourslist.push({
                        time: {
                            hours: i,
                            mins: j
                        }
                    })
                }
            }

        }
        setHours(hourslist);
        SetSelectedTime(hourslist[0])
        
        
    }

    return (
        <div>
            <SimpleBarReact style={{ maxWidth: "100%" }}>
                <div className="my-2">
                    <div className="flex flex-row">
                        {daysofmonth.map((day, index) => <DateBox key={index} selected={selectedDay} onPress={() => SetSelectedDay(day)} day={day} />)}
                    </div>
                </div>
            </SimpleBarReact>

            <SimpleBarReact style={{ maxWidth: "100%" }}>

                <div className="my-3">
                    <div className="flex flex-row">
                        {hours.map((hour, index) => <HoursBox key={index} selected={selectedTime} onPress={() => SetSelectedTime(hour)} day={selectedDay} hour={hour} />)}
                    </div>
                </ div>
            </SimpleBarReact>
        </div>
    )

}
export default OrderHours;