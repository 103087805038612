import React, { CSSProperties, useEffect, useState } from 'react';
import Select from 'react-select';

interface IngredientsInterface {
    toppings?: any[],
    onChange?: (e) => void,
    selected?: any
}
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: "sofiaPro",
    fontSize: 14

};
const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

export interface IngredientOption {
    readonly value: string;
    readonly label: string;
}


export interface GroupedOption {
    readonly label: string;
    readonly options: readonly IngredientOption[];
}


const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);


const CustomDessertIngredients = ({ toppings, onChange, selected }: IngredientsInterface) => {
    const [noix, setNoix] = useState([])
    const [fruit, setFruit] = useState([])
    const [saupoudrage, setSaupoudrage] = useState([])
    const [garnitures, setGarnitures] = useState([])

    useEffect(() => {
        if (toppings) {
            let TNoix = []
            let TFruits = []
            let TSaupoudrage = []
            let TGarnitures = []
            var promise = toppings.map((item) => {
                if (item.parent === "garnitures") {
                    TGarnitures.push(item)
                } else if (item.parent === "noix") {
                    TNoix.push(item)
                } else if (item.parent === "fruits frais") {
                    TFruits.push(item)
                } else {
                    TSaupoudrage.push(item)
                }
            })
            Promise.all(promise)
                .then(function (results) {
                    setNoix(TNoix)
                    setFruit(TFruits)
                    setSaupoudrage(TSaupoudrage)
                    setGarnitures(TGarnitures)
                })
        }
    }, [toppings])


    const groupedOptions: readonly GroupedOption[] = [
        {
            label: 'Fruits frais',
            options: fruit,
        },
        {
            label: 'Garniture',
            options: garnitures,
        },
        {
            label: 'Noix',
            options: noix,
        },

        {
            label: 'Saupoudrage',
            options: saupoudrage,
        },

    ];
    return (
        <Select<IngredientOption, false, GroupedOption>
            isClearable
            value={selected}
            options={groupedOptions}
            onChange={onChange}
            placeholder="Selectionnez ..."
            formatGroupLabel={formatGroupLabel}
        />

    )
}
export default CustomDessertIngredients;