import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import axios from '../../features/axiosInstances/axios';
import { disconnectUser, user } from '../../features/userSlice';
import { pot, potrestaurant, Variant } from '../../features/dataInterface';
import { addItems, cartInfos, setCart } from '../../features/cartSlice';
import { addCartItem } from '../../features/services';
import { useNavigate } from 'react-router-dom';
import { addCartItemToppingsAmount } from '../../features/services/cart.services';
import Swal from 'sweetalert2';

interface MenuItemProps {
    item: Variant,

    selectedPot: {
        id: number,
        pot: pot
    }
}


const ListMenuItem = ({ item, selectedPot }: MenuItemProps) => {
    const [count, setCount] = useState(1)
    const [unavailable, setUnAvailable] = useState(false)
    const userInfos = useAppSelector(user)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cartDetails = useAppSelector(cartInfos)

    useEffect(() => {
        // if selected pot is silver and dessert is different then tiramissu 
        if (selectedPot && item) {
            setUnAvailable(selectedPot.pot.id === 13 && (item.dessert.id.toString() === '14' || item.dessert.id.toString() === '18'))
        }
    }, [item, selectedPot])

    const AddToCart = (variant: Variant) => {
        addCartItemToppingsAmount(variant.id, null, count, selectedPot.id, userInfos.token)
            //addCartItem(userInfos.token, variant.id, count)
            .then(res => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Dessert ajouté au panier!',
                    showConfirmButton: false,
                    timer: 1500
                })
                axios.get("/cart", {
                    headers: { "Authorization": `Bearer ${userInfos.token}` }
                })
                    .then(res => {
                        if (res.data) {
                            dispatch(setCart(res.data))

                        }
                    })
                    .catch(err => {
                        console.log(err.response);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Une erreur est survenue !',
                        })
                    })

            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }

    return (
        item && selectedPot ?
            <tr className="md-max:block md-max:m-3 md-max:mx-1">
                <td data-content="Dessert" className=" md-flex md-justify-between px-4  w-2/6 py-2 md-max:block ">
                    <h3 className="text-xl text-snack-gray-dark md-max:text-center md-max:text-2xl font-bebas">
                        {item.title}
                    </h3>
                </td>
                <td data-content="Famille" className=" w-1/6 md-max:flex md-max:justify-between px-4 py-2 md-max:text-left md-max:before:content-[attr(data-content)] md-max:before:text-left ">
                    <p className="text-lg font-medium text-snack-pink-original">{item.dessert.label}</p>
                </td>

                <td data-content="Quantité" className="  w-1/6 md-max:flex md-max:justify-between px-4  py-2 md-max:text-left md-max:before:content-[attr(data-content)] md-max:before:text-left ">
                    <div className="flex justify-center bg-snack-gray-light rounded-3xl h-1/3 px-3">
                        <svg onClick={() => { if (count > 1) setCount(count - 1) }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                            <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                        </svg>
                        <div className='flex justify-center items-center'>
                            <input className="mx-2 text-center w-8 bg-transparent border-0 focus:outline-none" type="text"
                                onChange={(e) => { if(e.target.value){setCount(parseInt(e.target.value))}else setCount(1) }} value={count} />
                        </div>
                        <svg onClick={() => { setCount(count + 1) }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                            <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                        </svg>
                    </div>
                </td>
                <td data-content="Actions" className=" w-2/6 md-max:flex md-max:justify-between px-4 py-2 ">
                    <div className="flex flex-col md-max:flex-row items-center justify-between w-full py-2 ">
                        {
                            (selectedPot && selectedPot.pot) ?
                                <button onClick={() => {
                                    if (unavailable) {
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'Oops...',
                                            text: "Ce dessert n'est pas disponible dans le pot sélectionné!",
                                        })
                                    } else
                                        AddToCart(item)
                                }}
                                    className="bg-snack-pink-original hover:opacity-80  text-snack-pink-light py-2 my-1 px-4 rounded-full inline-flex justify-center items-center">
                                    <span>Ajouter au panier</span>
                                </button>
                                : null
                        }

                        {/* {
                            ((item.dessert.id.toString() === "12") || (item.dessert.id.toString() === "14") || (item.dessert.id.toString() === "15") || (item.dessert.id.toString() === "18")) ?
                                <button onClick={() => {
                                    navigate("/details", {
                                        state: { variant: item, pot: selectedPot }
                                    })
                                }
                                } className=" bg-snack-pink-original hover:bg-snack-pink-light hover:text-snack-pink-original text-snack-pink-light py-2 px-4 rounded-full inline-flex justify-center items-center">
                                    <span>Personnaliser</span>
                                </button>
                                : null
                        } */}
                    </div>
                </td>

            </tr >

            : null
    );
}
export default ListMenuItem