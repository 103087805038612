import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { address, profile } from './dataInterface';


export const orderSlice = createSlice({
  name: 'order',
  initialState: {
   date: ''
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDeliveryDateHour: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
  },
});

export const { setDeliveryDateHour } = orderSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const order = (state: RootState) => state.order.date;

export default orderSlice.reducer;
