import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import axios from '../../features/axiosInstances/axios';
import { user } from '../../features/userSlice';
import { pot, Variant } from '../../features/dataInterface';
import { cartInfos } from '../../features/cartSlice';
import { useNavigate } from 'react-router-dom';
import { addCartItemToppingsAmount } from '../../features/services/cart.services';
import { Checkbox } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface MenuItemProps {
    item: Variant,
    selectedPot: {
        id: number,
        pot: pot
    },
    selected: Variant,
    onClick: () => void
}


const CustomListMenuItem = ({ item, selectedPot, selected, onClick }: MenuItemProps) => {
    //const [count, setCount] = useState(1)
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        if (!selected) {
            setIsChecked(false)
        }
        else if (selected && item) {
            setIsChecked(selected.id === item.id)
        }

    }, [item, selected])

    return (
        item ?
            <tr className="md-max:block md-max:m-5 w-full px-10 py-3 justify-between md-max:mx-2">
                <td data-content="Dessert" className="md-flex w-full  md-max:block ">
                    <h3 className="text-xl text-snack-gray-dark md-max:text-2xl font-bebas">
                        {item.dessert.label}
                    </h3>
                </td>

                <td data-content="Actions" className="md-max:flex w-full items-center py-2 ">
                    <Checkbox
                        checked={isChecked}
                        onChange={onClick}
                        icon={<RadioButtonUncheckedIcon fontSize="large" style={{ fill: "#5EC394" }} />}
                        checkedIcon={<CheckCircleIcon fontSize="large" style={{ fill: "#5EC394" }} />}
                    />
                </td>
            </tr >
            : null
    );
}
export default CustomListMenuItem