import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addCartItem, getAllCustomisableVariantType, getAllIngredientsWithPrice } from "../features/services";
import { user } from "../features/userSlice";
import Swal from 'sweetalert2';
import { addItems, cartInfos, setCart } from "../features/cartSlice";
import { axiosApi } from "../features/axiosInstances";
import { isTemplateExpression } from "typescript";
import Select from 'react-select'
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { addCartItemToppingsAmount } from "../features/services/cart.services";


const DessertDetails = () => {
    const { state } = useLocation();
    const [variant, setVariant] = useState(state.variant)
    const [count, setCount] = useState(1)
    const userInfos = useAppSelector(user)
    const cartDetails = useAppSelector(cartInfos)
    const [Tings, setTIngs] = useState(null)
    const [Sings, setSIngs] = useState(null)
    const [ToppingsOptions, setToppingsOptions] = useState([])
    const [SaucesOptions, setSaucesOptions] = useState([])
    const [originalIngs, setOriginalIngs] = useState([])
    const [parentIngs, setParentIngs] = useState([])

    const dispatch = useAppDispatch();

    useEffect(() => {
        getAllIngredientsWithPrice()
            .then(res => {
                let tabT = []
                let tabS = []
                res.data.map((item) => {
                    if (item.type == "sauces et purees") {
                        tabS.push({ label: item.label, value: item.id }) //+ ' €' + item.price, value: item.id, price: item.price 
                    } else {
                        tabT.push({ label: item.label, value: item.id }) //+ ' €' + item.price, value: item.id, price: item.price 
                    }
                })
                setToppingsOptions(tabT);
                setSaucesOptions(tabS);
            })
            .catch(err => {
                console.log("err in ings");
            })

        getAllCustomisableVariantType(state.variant.dessert.id)
            .then((res) => {

                let tab = []
                res.data[0].ingredient.map((item) => {
                    tab.push(item.id)
                })
                setParentIngs(tab)
            })
            .catch((err) => {
                console.log(err);

            })
    }, [])
    useEffect(() => {
        let tab = []
        console.log(variant.ingredient);

        state.variant.ingredient.map((item) => {
            tab.push(item.id)
        })
        setOriginalIngs(tab)

    }, [state])

    const AddToCart = () => {
        let uni = [...new Set([...originalIngs, ...parentIngs])] // get unique array of original dessert + picked to custom
        let finalIngs = []
        let allIngs = []
        if (Tings && Sings) { // if there is custom toppings and sauces
            allIngs = [...uni, Tings.value, Sings.value];
        } else if (Tings && !Sings) { // only toppings selected
            allIngs = [...uni, Tings.value];
        } else if (Sings && !Tings) { // only sauces selected
            allIngs = [...uni, Sings.value];
        } else {
            finalIngs = uni
        }
        allIngs.forEach(element => {
            finalIngs.push({ "id": element, "amount": 1 })
        });

        addCartItemToppingsAmount(variant.id, finalIngs, count, state.pot, userInfos.token)
            /* addCartItem(userInfos.token, variant.id, count)*/
            .then(res => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Dessert ajouté au panier!',
                    showConfirmButton: false,
                    timer: 1500
                })
                axiosApi.get("/cart", {
                    headers: { "Authorization": `Bearer ${userInfos.token}` }
                })
                    .then(res => {
                        if (res.data) {
                            dispatch(setCart(res.data))

                        }
                    })
                    .catch(err => {
                        console.log(err.response);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Une erreur est survenue !',
                        })
                    })
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }

    const toggleToppings = (ingredient) => {

        if (originalIngs.includes(ingredient.id)) {
            let filteredList = originalIngs.filter((item) => item != ingredient.id);
            setOriginalIngs(filteredList);

        } else {
            //if item is not in toppings list then add it and add value to price
            setOriginalIngs([...originalIngs, ingredient.id]);
        }

        //if item selected deselect and remove price

    }

    return (
        <div className="max-w-2xl mx-auto  px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8 my-4">

            <section className=" text-gray-700 body-font bg-white rounded-lg">
                <div className="container px-5 py-24 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap ">
                        <img className="lg:w-1/2 md:w-1/3 w-full object-cover object-center rounded border border-gray-200" src={variant.cover.server + "/" + variant.cover.filename} />
                        <div className="lg:w-1/2 md:w-1/3 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                            <h2 className="text-sm title-font text-gray-500 tracking-widest">SNACK TIME</h2>
                            <div className="flex flex-row flex-wrap items-center">
                                <h1 className="text-gray-900 text-2xl title-font font-medium mb-1">{variant.title}</h1>
                                <span className="title-font font-medium text-xl text-gray-900">: {variant.price.toFixed(2)}€</span>
                            </div>
                            <div className="flex flex-wrap mb-4">
                                {variant.ingredient.map((item, index) => (
                                    item.type ?
                                        <div className={`border-2 flex flex-row items-center rounded-xl mr-2 mb-2 ${originalIngs.includes(item.id) ? "text-snack-green-original border-snack-green-original" : "text-snack-pink-original border-snack-pink-original"}`} key={index} onClick={() => { toggleToppings(item) }}>

                                            <div className="flex flex-row font-sofiapro items-center justify-center rounded py-1 px-2">
                                                <p >{item.label.toLowerCase()}</p>
                                            </div>
                                            <div className="flex flex-row items-center justify-center rounded px-2" >
                                                {originalIngs.includes(item.id) ?
                                                    <RemoveCircle />
                                                    :
                                                    <AddCircle />
                                                }
                                            </div>

                                        </div>
                                        : null
                                ))}

                            </div>
                            
                            <div className="flex flex-row justify-between w-3/4">
                                <p className="font-sofiapro text-lg">Quantité souhaitée:</p>
                                <div className="flex justify-center items-center bg-snack-gray-light rounded-3xl px-3">
                                    <svg onClick={() => { if (count > 1) setCount(count - 1) }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                        <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                    </svg>

                                    <input className="mx-2 text-center w-8 bg-transparent border-0 focus:outline-none" value={count} type="text"
                                    />
                                    <svg onClick={() => { setCount(count + 1) }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                        <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-6 pb-5 border-b-2 border-gray-200 mb-5">

                        </div>
                        <div className="flex flex-col w-full">


                            <div className="flex flex-col p-10 mb-3">
                                <div className="flex flex-row justify-center w-full h-full font-sofiapro text-2xl pb-5 text-snack-pink-original">
                                    Veuillez sélectionner un topping et une sauce dans votre {state.variant.title}
                                </div>

                                <div className="flex flex-row flex-wrap justify-evenly w-full h-full">
                                    <div className="md:w-2/5 w-full ">
                                        <p className="font-medium">Toppings</p>
                                        <Select className="w-full "
                                            options={ToppingsOptions}
                                            isClearable
                                            onChange={selectedOption => {
                                                setTIngs(selectedOption);
                                            }}
                                        />
                                    </div>
                                    <div className="md:w-2/5 w-full">
                                        <p className="font-medium">Sauces</p>
                                        <Select className="w-full "
                                            options={SaucesOptions}
                                            isClearable
                                            onChange={selectedOption => {
                                                setSIngs(selectedOption);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* disabled={!(Tings || Sings) !(Tings || Sings)} */}
                            <button onClick={AddToCart} className={" bg-red-500 border-0 flex ml-auto text-white  py-2 px-6 focus:outline-none hover:bg-red-600 rounded"}>Ajouter au panier</button>
                        </div>

                    </div>
                </div>
            </section >
        </div >
    )
}
export default DessertDetails;