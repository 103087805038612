import { Circle } from "@mui/icons-material";

export const CupCake = ({ fill }) => {
    return (

        <svg width={21.277} height={21.277}>
            <path
                d="M10.639 13.3a18.961 18.961 0 0 1-5.34-.717 10.314 10.314 0 0 1-3.875-1.943A3.509 3.509 0 0 1 0 7.979a3.753 3.753 0 0 1 1.849-3A12.334 12.334 0 0 1 6.69 3.034 3.877 3.877 0 0 0 7.968 5.61a3.951 3.951 0 0 0 5.341 0 3.88 3.88 0 0 0 1.278-2.576 12.329 12.329 0 0 1 4.841 1.943 3.755 3.755 0 0 1 1.849 3 3.512 3.512 0 0 1-1.423 2.66 10.308 10.308 0 0 1-3.875 1.943 18.965 18.965 0 0 1-5.34.72Zm0-7.979a2.564 2.564 0 0 1-1.881-.781 2.56 2.56 0 0 1-.779-1.88A2.566 2.566 0 0 1 8.758.779a2.66 2.66 0 0 1 3.761 0A2.566 2.566 0 0 1 13.3 2.66a2.56 2.56 0 0 1-.779 1.881 2.564 2.564 0 0 1-1.882.778ZM4.571 20.342q-1.911-.748-1.911-1.724l-1.33-6.65a7.977 7.977 0 0 0 2.7 1.745Zm.81-6.192a16.734 16.734 0 0 0 2.6.415v6.566a20.5 20.5 0 0 1-2.036-.374Zm6.587.457v6.628q-.706.041-1.33.041t-1.33-.041v-6.628q.52.021 1.33.021.831 0 1.33-.021Zm3.366 6.15a20.5 20.5 0 0 1-2.036.374v-6.565a16.734 16.734 0 0 0 2.6-.415Zm4.613-8.789-1.33 6.649q0 .976-1.911 1.725l.54-6.628a7.977 7.977 0 0 0 2.7-1.745Z"
                fill={fill}
            />
        </svg>

    );
}

export const Home = ({ fill }) => {
    return (

        <svg width={21.312} height={21.348}>
            <g fill={fill}>
                <path
                    data-name="Trac\xE9 4"
                    d="M20.647 11.338a.667.667 0 0 1-.474-.194L10.64 1.602l-9.539 9.543a.667.667 0 0 1-.941-.941L10.17.191a.667.667 0 0 1 .941 0l10.01 10.01a.667.667 0 0 1-.474 1.134Z"
                />
                <path
                    data-name="Trac\xE9 5"
                    d="m10.637 3.858-8.008 8.034v8.121a1.335 1.335 0 0 0 1.335 1.335h4.671v-6.673h4v6.673h4.671a1.335 1.335 0 0 0 1.335-1.335v-8.167Z"
                />
            </g>
        </svg>

    );
}
export const Info = ({ fill }) => {
    return (

        <svg width={21.277} height={21.277}>
            <g fill={fill}>
                <path
                    data-name="Trac\xE9 7"
                    d="M11.399 15.198V8.359h-3.04v1.52h1.52v5.319h-2.28v1.52h6.079v-1.52Z"
                />
                <path
                    data-name="Trac\xE9 8"
                    d="M10.638 3.799a1.14 1.14 0 1 0 1.14 1.14 1.14 1.14 0 0 0-1.14-1.14Z"
                />
                <path
                    data-name="Trac\xE9 9"
                    d="M10.639 21.277a10.639 10.639 0 1 1 10.638-10.638 10.639 10.639 0 0 1-10.638 10.638Zm0-19.757a9.119 9.119 0 1 0 9.119 9.119 9.119 9.119 0 0 0-9.119-9.119Z"
                />
            </g>
        </svg>

    );
}
export const Book = ({ fill }) => {
    return (

        <svg width={21.277} height={18.01}>
            <path
                data-name="Trac\xE9 6"
                d="M20.518 0h-8.359l-.532.228-.988.973L9.65.228 9.119 0H.76L0 .76v15.2l.76.76H8.8l1.307 1.292h1.064l1.307-1.292h8.04l.76-.76V.76l-.76-.76ZM9.879 15.685l-.274-.259-.486-.228H1.52V1.52H8.8l1.125 1.125-.046 13.04Zm9.879-.486h-7.6l-.532.228-.213.2V2.584l1.065-1.064h7.28ZM7.599 4.56H3.04v1.52H7.6Zm0 6.079H3.04v1.52H7.6ZM3.04 7.6H7.6v1.52H3.04Zm15.2-3.04h-4.56v1.52h4.56ZM13.68 7.6h4.56v1.52h-4.56Zm0 3.04h4.56v1.52h-4.56Z"
                fill={fill}
            />
        </svg>

    );
}
export const Cart = () => {
    return (

        <svg width={22.69} height={16.207}>
            <path
                data-name="Icon zocial-cart"
                d="M0 .932A.907.907 0 0 1 .263.263.9.9 0 0 1 .932 0h4.761a.929.929 0 0 1 .577.192.9.9 0 0 1 .334.517l.486 1.985h13.655l.142-.425a.838.838 0 0 1 .871-.648.624.624 0 0 1 .263.041.873.873 0 0 1 .486.324.885.885 0 0 1 .182.547 1.132 1.132 0 0 1-.041.263l-2.391 7.962a.871.871 0 0 1-.324.486.89.89 0 0 1-.547.182H8.063a.9.9 0 0 1-.577-.2.946.946 0 0 1-.334-.507L4.964 1.844H.932A.916.916 0 0 1 .27 1.58.865.865 0 0 1 0 .932Zm7.536 3.606 1.256 5.024h9.907l.04-.182 1.459-4.842H7.536Zm.851 9.806a1.778 1.778 0 0 1 .557-1.317 1.83 1.83 0 0 1 1.326-.547 1.864 1.864 0 0 1 0 3.727 1.829 1.829 0 0 1-1.327-.546 1.78 1.78 0 0 1-.556-1.317ZM9.704 6.12h3.366v1.863h-2.961Zm4.966 1.862.02-1.863h3.28l-.486 1.863Zm.75 6.361a1.778 1.778 0 0 1 .558-1.317 1.828 1.828 0 0 1 1.327-.547 1.864 1.864 0 0 1 0 3.727 1.828 1.828 0 0 1-1.327-.546 1.778 1.778 0 0 1-.561-1.316Z"
                fill="#a5adc6"
            />
        </svg>

    );
}
export const ArowDown = () => {
    return (

        <svg width={13.326} height={7.619}>
            <path
                data-name="Icon ionic-ios-arrow-down"
                d="M6.665 5.323 11.704.28a.948.948 0 0 1 1.345 0 .96.96 0 0 1 0 1.349L7.34 7.342a.951.951 0 0 1-1.313.028L.277 1.633A.952.952 0 1 1 1.622.284Z"
                fill="#a5adc6"
            />
        </svg>

    );
}
export const Pdf = () => {
    return (

        <svg className="fill-current w-7 h-7" viewBox="0 0 303.188 303.188"  >
            <g>
                <polygon fill="#E8E8E8" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	" />
                <path fill="#FB3449" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
		c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
		c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
		c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
		c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
		c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
		c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
		c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
		c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
		c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
		c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
		C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
		c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
		c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
		c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
		c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
		c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
		c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
		c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
		c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
		 M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
		c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
		c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                <polygon fill="#FB3449" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	" />
                <g>
                    <path fill="#A4A9AD" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
			 M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
			h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                    <path fill="#A4A9AD" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
			h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
			c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
			C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                    <path fill="#A4A9AD" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
			L196.579,273.871L196.579,273.871z"/>
                </g>
                <polygon fill="#D1D3D3" points="219.821,50.525 270.346,50.525 219.821,0 	" />
            </g>
        </svg>

    );
}
export const Eye = () => {
    return (

        <svg className="fill-current w-7 h-7" viewBox="0 0 576 512">
            <path fill="currentColor"
                d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
            </path>
        </svg>

    );
}
export const List = ({ fill }) => {
    return (

        <svg
            width={24} height={18}
            viewBox="0 0 512 512"
        >
            <path
                fill={fill}
                d="M48 48a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 16H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
            />
        </svg>

    );
}
export const Invoice = ({ fill }) => {
    return (

        <svg
            width={24} height={20}
            viewBox="0 0 384 512"
        >
            <path
                fill={fill}
                d="M377 105 279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z"
            />
        </svg>

    );
}
export const Calander = ({ fill }) => {
    return (

        <svg
            fill={fill}
            width={20}
            height={20}
            viewBox="0 0 100 100"
        >
            <path d="M2.13 85.34a2.3 2.3 0 0 1 2.51-1.48h4.33c-3.57-3.42-5.42-7.4-5.21-12.21A14.75 14.75 0 0 1 9.9 60a15.3 15.3 0 0 1 20 1.45 15.55 15.55 0 0 1-.72 22.39H46.88a5 5 0 0 1 1.29.13 1.81 1.81 0 0 1 1.36 1.79 1.85 1.85 0 0 1-1.54 1.75 7 7 0 0 1-1.21.06H5.94a27.65 27.65 0 0 0 0 3.65A3.2 3.2 0 0 0 9 93.91h75l-.66-6.33H64.12a2.13 2.13 0 0 1-2.12-.93A1.81 1.81 0 0 1 63 84a4.55 4.55 0 0 1 1.38-.17h20.24c2 0 2.6.6 2.6 2.63v4a3.35 3.35 0 1 0 6.7 0V20.22a5.69 5.69 0 0 0-3.13-5.59 4 4 0 0 0-1.79-.47c-3.16-.06-6.33 0-9.64 0v2.69a5.55 5.55 0 0 1-11 .28c-.1-1-.1-1.92-.16-3H36.84v2.68a5.55 5.55 0 0 1-11.05.28c-.1-1-.11-2-.16-3-2.86 0-5.69-.14-8.5 0a5.34 5.34 0 0 0-4.92 5.31c-.06 2.07 0 4.15 0 6.35h67.23a1.85 1.85 0 0 1 0 3.7H12.18v21.16a5.54 5.54 0 0 1-.13 1.29 1.7 1.7 0 0 1-1.84 1.37 1.8 1.8 0 0 1-1.72-1.55 5.74 5.74 0 0 1 0-.93v-30.6c0-5.88 4-9.85 9.92-9.85h7.37c0-1-.07-2 0-3a5.44 5.44 0 0 1 4.12-5.02 2.93 2.93 0 0 0 .4-.19h1.87c4.28 1.13 5.06 4.35 4.68 8.15h31.38a12.14 12.14 0 0 1 0-1.71 16 16 0 0 1 .7-3.41c.68-1.72 2.26-2.46 3.9-3h1.87c4.29 1.13 5.07 4.35 4.68 8.21h9a9.36 9.36 0 0 1 9.31 9.34V90.3a8.12 8.12 0 0 1-.73 3.33c-1.34 2.85-3.76 4.06-6.86 4.06H9.74a7.58 7.58 0 0 1-5.17-1.77 8.13 8.13 0 0 1-2.44-4.42Zm28.43-13.07A11.56 11.56 0 1 0 19 83.85a11.56 11.56 0 0 0 11.56-11.58Zm2.59-60.16V8a1.87 1.87 0 1 0-3.71-.09q-.06 4.15 0 8.3a1.82 1.82 0 0 0 1.95 2c1.07 0 1.73-.81 1.75-2.1s.01-2.67.01-4Zm42.54.05v-4.2a1.86 1.86 0 1 0-3.7-.1q-.08 4.2 0 8.39a1.86 1.86 0 1 0 3.7-.08c.02-1.34 0-2.67 0-4.01Z" />
            <path d="M68.8 46.23a7.05 7.05 0 1 1-7.42-6.76 7.06 7.06 0 0 1 7.42 6.76Zm-10.38.27a3.34 3.34 0 1 0 3.33-3.32 3.36 3.36 0 0 0-3.33 3.32ZM54.69 65.93a7.07 7.07 0 1 1 7 7.09 7.12 7.12 0 0 1-7-7.09ZM65.1 66a3.34 3.34 0 1 0-3.4 3.34A3.36 3.36 0 0 0 65.1 66ZM19.85 46.53a7.08 7.08 0 1 1 7 7.09 7.14 7.14 0 0 1-7-7.09Zm3.73 0a3.35 3.35 0 1 0 3.32-3.34 3.37 3.37 0 0 0-3.32 3.33ZM79.18 39.45a7.09 7.09 0 1 1-7.09 7 7.17 7.17 0 0 1 7.09-7Zm0 10.44a3.36 3.36 0 1 0 0-6.71 3.36 3.36 0 0 0 0 6.71ZM79 73a7.08 7.08 0 1 1 7.23-7A7.13 7.13 0 0 1 79 73Zm-3.2-7.13a3.35 3.35 0 0 0 3.34 3.4 3.35 3.35 0 1 0 0-6.7 3.35 3.35 0 0 0-3.31 3.32ZM51.41 46.49a7.08 7.08 0 1 1-7.09-7 7.13 7.13 0 0 1 7.09 7Zm-3.74 0a3.34 3.34 0 0 0-3.3-3.33 3.36 3.36 0 1 0 0 6.71 3.34 3.34 0 0 0 3.3-3.36ZM51.41 66a7.08 7.08 0 1 1-7-7.09 7.11 7.11 0 0 1 7 7.09Zm-3.74 0a3.33 3.33 0 0 0-3.34-3.38 3.35 3.35 0 1 0 0 6.7A3.34 3.34 0 0 0 47.67 66ZM90 27.73a1.87 1.87 0 0 1-1.87 1.81 1.85 1.85 0 1 1 .08-3.7A1.88 1.88 0 0 1 90 27.73ZM54.71 85.75a1.88 1.88 0 0 1 1.82-1.87 1.9 1.9 0 0 1 1.84 1.85 1.87 1.87 0 0 1-1.83 1.85 1.82 1.82 0 0 1-1.83-1.83ZM16.93 73.94c1-1 1.81-1.91 2.67-2.79 1.11-1.15 2.22-2.26 3.4-3.34a1.84 1.84 0 0 1 2.67 0 1.81 1.81 0 0 1-.06 2.67q-3.61 3.61-7.27 7.19a1.86 1.86 0 0 1-2.82 0q-1.62-1.55-3.16-3.16A1.86 1.86 0 1 1 15 71.87c.63.61 1.21 1.3 1.93 2.07Z" />
        </svg>

    );
}
export const PastClock = ({ fill }) => {
    return (

        <svg
            fill={fill}
            viewBox="0 0 100 100"
            width={20}
            height={20}
        >
            <path d="M79.35 14.87V9.13a6.74 6.74 0 0 1 .28-2 4.05 4.05 0 0 1 4.45-2.71 4.11 4.11 0 0 1 3.6 4.1v16.79a4.05 4.05 0 0 1-4 4q-8.49.08-17 0a4.13 4.13 0 0 1 0-8.26h5.76a2.11 2.11 0 0 0 1-.21c-.29-.23-.58-.46-.88-.68C50.69 3.75 19 15.76 13.45 42.58a37.41 37.41 0 0 0 30.44 44.55 51.94 51.94 0 0 0 6.11.55 4.19 4.19 0 1 1-.1 8.32A45.81 45.81 0 0 1 41 5.36c13.7-2.75 26.2.3 37.36 8.74Z" />
            <path d="M46.05 37.72V25.03a4 4 0 0 1 3.52-3.94 4.09 4.09 0 0 1 4.6 2.91 7.05 7.05 0 0 1 .17 1.84v21.38a2.59 2.59 0 0 0 .83 2q5.21 5.14 10.36 10.35a4.15 4.15 0 0 1-1.66 7 3.85 3.85 0 0 1-4-.93c-4.25-4.11-8.47-8.36-12.7-12.64A4.14 4.14 0 0 1 46 49.92v-12.2ZM83.79 79.65a4.17 4.17 0 1 1-4.11-4.2 4.17 4.17 0 0 1 4.11 4.2ZM66.12 84.57a4.16 4.16 0 1 1 0 8.32 4.16 4.16 0 0 1 0-8.32ZM91.83 54.36A4.15 4.15 0 1 1 96 50.23a4.14 4.14 0 0 1-4.17 4.13ZM88.72 62.05a4.15 4.15 0 1 1-4.16 4.15 4.14 4.14 0 0 1 4.16-4.15Z" />
        </svg>

    );
}
export const HourGlass = ({ fill }) => {
    return (

        <svg
            fill={fill}
            viewBox="0 0 66.75 91.73"
            width={20}
            height={20}
        >
            <path d="M33.28 91.68H12.77A12.42 12.42 0 0 1 .29 81.48a28.61 28.61 0 0 1-.25-5.25 37.85 37.85 0 0 1 .24-5.64A11.71 11.71 0 0 1 4.85 63q6.58-5.2 13.2-10.37c2.13-1.65 4.64-1.41 6.13.55a4.19 4.19 0 0 1-1 6c-4.24 3.35-8.47 6.7-12.74 10a4.78 4.78 0 0 0-2.06 4.17c.06 1.89 0 3.78 0 5.67a4.14 4.14 0 0 0 4.28 4.33h41.41a4.14 4.14 0 0 0 4.3-4.31v-5.67a4.81 4.81 0 0 0-2-4.19q-10.89-8.5-21.75-17.03Q19.85 40.54 5.07 28.94a12.52 12.52 0 0 1-5-10.3c0-2.41-.12-4.83.11-7.22A12.3 12.3 0 0 1 12.48.05q20.89-.11 41.79 0a12.2 12.2 0 0 1 12.31 11.37 58.05 58.05 0 0 1-.24 10.26 10 10 0 0 1-3.92 6.67q-7 5.61-14 11.05a4.07 4.07 0 0 1-5.82-.78 4.13 4.13 0 0 1 .79-5.83c4.28-3.39 8.58-6.78 12.9-10.12a4.9 4.9 0 0 0 2.13-4.34c-.07-1.82 0-3.65 0-5.47a4.15 4.15 0 0 0-4.38-4.43H12.78a4.15 4.15 0 0 0-4.4 4.41v5.76a4.52 4.52 0 0 0 1.88 3.86l24.71 19.36q13.32 10.47 26.64 20.92a12.66 12.66 0 0 1 5.11 10.56c0 2.31.11 4.64-.1 6.93a12.4 12.4 0 0 1-12.64 11.45c-6.9.07-13.8 0-20.7 0Z" />
        </svg>

    );
}