import { axiosApi, axiosDash } from "../axiosInstances";

export const getUserCart = (token) => {
    return axiosApi.get("/cart", {
        headers: { "Authorization": `Bearer ${token}` }
    });
}
export const addCartItem = (token, variant_id, amount) => {
    return axiosApi.post("/cart", { "variant_id": variant_id, "amount": amount }, {
        headers: { "Authorization": `Bearer ${token}` }
    });
}
export const addCartItemToppingsAmount = (variant_id, toppings, amount, pot, token) => {
    return axiosApi.post("/cart", { "variant_id": variant_id, "toppings": toppings, "amount": amount, "pot_id": pot }, {
        headers: { "Authorization": `Bearer ${token}` }
    });
}
export const updateCartItemsDB = (token, itemId, quantity) => {
    return axiosApi.patch(`/cart/${itemId}`, { "amount": quantity }, {
        headers: { "Authorization": `Bearer ${token}` }
    });
}
export const deleteCartItem = (token, itemId) => {
    return axiosApi.delete(`/cart/${itemId}`, {
        headers: { "Authorization": `Bearer ${token}` }
    });
}
export const applyCartCoupon = (token, code) => {
    return axiosApi.post("/cart/coupon", { "code": code }, {
        headers: { "Authorization": `Bearer ${token}` }
    });
}

export const deleteCartItems = (id) => {
    return axiosDash.delete(`/cart_items/${id} `);
}

export default getUserCart;
