import { Formik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import axios from '../../features/axiosInstances/axios';

import { Box } from '@mui/material';
import { updatePhoneNumber, user } from '../../features/userSlice';
import Swal from 'sweetalert2';

interface PasswordUpdateProps {
    modalVisible: boolean,
    closeMod: () => void
}

const PasswordUpdate = ({ modalVisible, closeMod }: PasswordUpdateProps) => {
    const userInfos = useAppSelector(user)

    const passwordSchema = yup.object({
        old_password: yup.string().default('').required('Ancienne Mot de passe obligatoire'),
        new_pass: yup.string().default('').required('Nouveau Mot de passe '),
        confirm_pass: yup.string().default('').required('Confirmer Mot de passe '),
    });
    return (
        modalVisible ? (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-full my-6 mx-auto max-w-xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-xl font-semibold">
                                    Changer votre mot de passe
                                </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={closeMod}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative w-full flex-auto">
                                <Formik
                                    validationSchema={passwordSchema}
                                    initialValues={{ old_password: '', new_pass: '', confirm_pass: '' }}
                                    onSubmit={(values) => {

                                        let passInfos = {
                                            "current": values.old_password,
                                            "password": values.new_pass,
                                            "password_confirmation": values.confirm_pass
                                        }
                                        console.log(passInfos);

                                        axios.patch("/me/password", passInfos, {
                                            headers: { "Authorization": `Bearer ${userInfos.token}` }
                                        })
                                            .then(res => {
                                                Swal.fire({
                                                    toast: true,
                                                    position: 'top-end',
                                                    icon: 'success',
                                                    title: 'Mode de passe modifié avec succès!',
                                                    showConfirmButton: false,
                                                    timer: 1500
                                                })
                                            })
                                            .then(closeMod)
                                            .catch(error => {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Oops...',
                                                    text: 'Une erreur est survenue !',
                                                })
                                            })
                                    }}
                                >
                                    {({ handleChange,
                                        handleSubmit,
                                        values,
                                        errors,
                                        isValid }) => (
                                        <>
                                            <Box className="border-b-2 px-2 w-full my-5 flex flex-row justify-evenly">
                                                <span className="w-1/2 text-gray-500">Mot de passe </span>
                                                <input
                                                    className=" w-1/2"
                                                    type={"password"}
                                                    placeholder="Votre Mot de passe"
                                                    value={values.old_password}
                                                    onChange={handleChange('old_password')}

                                                />
                                            </Box>
                                            {errors.old_password &&
                                                <span className="px-2 text-sm text-snack-pink-original">{errors.old_password}</span>
                                            }
                                            <Box className="border-b-2 px-2 w-full my-5 flex flex-row justify-evenly">
                                                <span className="w-1/2 text-gray-500">Nouveau Mot de passe </span>
                                                <input
                                                    className=" w-1/2"
                                                    type={"password"}
                                                    placeholder="Votre Mot de passe"
                                                    value={values.new_pass}
                                                    onChange={handleChange('new_pass')}

                                                />
                                            </Box>
                                            {errors.new_pass &&
                                                <span className="px-2 text-sm text-snack-pink-original">{errors.new_pass}</span>
                                            }
                                            <Box className="border-b-2 px-2 w-full my-5 flex flex-row justify-evenly">
                                                <span className="w-1/2 text-gray-500">Confirmer Mot de passe </span>
                                                <input
                                                    className=" w-1/2"

                                                    placeholder="Votre Mot de passe"
                                                    value={values.confirm_pass}
                                                    onChange={handleChange('confirm_pass')}
                                                    type={"password"}
                                                />
                                            </Box>
                                            {errors.confirm_pass &&
                                                <span className="px-2 text-sm text-snack-pink-original">{errors.confirm_pass}</span>
                                            }
                                            <div className="flex flex-row justify-end m-5">
                                                <button
                                                    className="bg-snack-green-original text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={closeMod}
                                                >
                                                    Fermer
                                                </button>
                                                <button
                                                    className="bg-snack-pink-original text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    Changer
                                                </button>
                                            </div>

                                        </>
                                    )}
                                </Formik>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null
    )
}
export default PasswordUpdate;