import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { authenticated } from './features/userSlice';
import './App.css';
import "simplebar/src/simplebar.css";
import { useAppSelector } from './app/hooks';
import Sidebar from './screens/components/Sidebar';
import Home from './screens/Home';
import { Login } from './screens/Login';
import Menu from './screens/Menu';
import Cart from './screens/Cart';
import Compte from './screens/Compte';
import Settings from './screens/Settings';
import ClientOrders from "./screens/ClientOrders";
import CustomDessert from './screens/CustomDessert';
import DessertDetails from './screens/DessertDetails';
import Invoices from './screens/Invoices';
import ListMenu from './screens/ListMenu';
import BonLivraison from './screens/BonLivraison';
import CustomDessertSelection from './screens/CustomDessertSelection';


function App() {
  const authorized = useAppSelector(authenticated);

  return (
    <Router>
      {!authorized ?
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        :
        <div className={'h-screen flex flex-col'}>
          <div className={'flex-1 bg-snack-gray-light'}>
            {/*  <div className={'flex-1 bg-gradient-to-tl from-snack-pink-light to-snack-green-light'}> */}

            <Sidebar />
            <div className="md:ml-64">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/menu" element={<ListMenu />} />
                <Route path="/orders" element={<ClientOrders />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/panier" element={<Cart />} />
                <Route path="/compte" element={<Compte />} />
                <Route path="/details" element={<DessertDetails />} />
                <Route path="/bonslivraison" element={<BonLivraison />} />
                {/* <Route path="/dessert_personnaliser" element={<CustomDessert />} /> */}
                <Route path="/parametres" element={<Settings />} />
              </Routes>
            </div>
          </div>
        </div>
      }

    </Router>
  );
}

export default App;
