import { useEffect, useState } from 'react';
import { deleteCartItem, updateCartItemsDB } from '../../features/services/cart.services';
import { cartItem } from '../../features/dataInterface';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { user } from '../../features/userSlice';
import axios from 'axios';
import { removeCartItem, updateDetails } from '../../features/cartSlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StarsIcon from '@mui/icons-material/Stars';

interface CartItemInterface {
    item: cartItem,
    allIngredients?: { id: number, label: string }[]
}
function CartItem({ item, allIngredients }: CartItemInterface) {
    const userInfos = useAppSelector(user)
    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(item.amount);
    const [shownIngredients, setShownIngredients] = useState(null);


    useEffect(() => {
        if (allIngredients && item) {
            let res = "";
            allIngredients.forEach(element => {
                item.toppings.forEach(all => {
                    if (all.id === element.id) {
                        res = res + element.label + ", "
                    }
                });

            });
            setShownIngredients(res);
        }

    }, [allIngredients, item]);



    const handleRemove = (item: cartItem) => {
        dispatch(removeCartItem({ id: item.id, price: item.pot.price, quantity: quantity }))
        deleteCartItem(userInfos.token, item.id)
            .then(res => {
                //item.product.id
                console.log('deleted fom db')
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }
    const updateInput = (token: string, id: number, qte: number) => {
        setQuantity(qte);
        updateCartItemsDB(token, id, qte)
            .then(res => {
                dispatch(updateDetails(res.data))
            })
            .catch(err => {
                console.log("amount couldnt be updated,", err.response);
            })
    }

    return (
        <div className="flex items-center bg-white  min-w-min px-6 py-6 mb-3 rounded-xl shadow-md md:mr-3">
            {item ?
                <>
                    <div className='w-2/6'>
                        <div className="flex md:w-3/4  items-center">
                            {/* <div className="h-20 w-20">
                                <img className="object-cover object-center h-20 w-20 rounded-xl" src={item.product && item.product.cover.main} alt="" />
                            </div> */}
                            <div onClick={() => handleRemove(item)} className="font-semibold cursor-pointer hover:text-red-500 text-gray-500 text-xs">
                                <DeleteForeverIcon titleAccess="Supprimer de votre panier" fontSize="large" />
                            </div>
                            <div className="flex flex-col justify-between ml-4 flex-grow pr-3">

                                <span className="font-bold text-sm">{item.product && item.product.title} </span>
                                {item.toppings.length > 0 ? (
                                    <span className="mb-2 bg-yellow-500 p-1 text-center font-sofiapro text-white rounded text-xs ">  Personnalisé <StarsIcon /></span>)
                                    : null}

                                <span className="bg-snack-pink-original p-1 text-center font-sofiapro text-white rounded text-xs">{item.product && item.product.dessert.label}</span>

                            </div>
                        </div>
                    </div>
                    <div className='w-1/6'>
                        {shownIngredients ? shownIngredients : ""}
                    </div>
                    <div className="flex justify-between items-center md:w-3/6">
                        <span className="text-center md:w-1/3 w-44 font-semibold text-sm">{item.pot.pot_original.size === undefined ? 'basique' :  item.pot.pot_original.material + " à €" + item.pot.price}</span>
                        <div className="flex justify-center md:w-1/3 w-44">
                            <svg onClick={() => { if (quantity > 1) updateInput(userInfos.token, item.id, quantity - 1); else console.log("no update") }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                            </svg>

                            <input className="mx-2 border text-center w-8" type="text"
                                onBlur={() => updateInput(userInfos.token, item.id, quantity)} onChange={(e) => { setQuantity(parseInt(e.target.value)) }} value={quantity} onFocus={() => console.log(quantity)} />
                            <svg onClick={() => { updateInput(userInfos.token, item.id, quantity + 1); }} className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                            </svg>
                        </div>
                        <span className="text-center md:w-1/3 w-44 font-semibold text-sm">{item.unit_price.toFixed(2) + "€"}</span>
                        <span className="text-center md:w-1/3 w-44 font-semibold text-m">{(item.unit_price * quantity).toFixed(2) + "€"}</span>
                    </div>
                </> : null}
        </div>
    )
}
export default CartItem;