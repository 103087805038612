import { Tab } from '@headlessui/react'
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useAppSelector } from '../app/hooks';
import { axiosDash } from '../features/axiosInstances';
import { user } from '../features/userSlice';
import Adress from './Adress';
import Contacts from './Contacts';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function Settings() {
    const userInfos = useAppSelector(user)
    const [OrderContact, setOrderContact] = useState(null)
    const [DeliveryContact, setDeliveryContact] = useState(null)

    useEffect(() => {
        axiosDash.get(`/contacts?User=${userInfos.profile.id}`)
            .then(res => {
                let delivery = res.data.filter((item) => item.isDelivery === true)
                let order = res.data.filter((item) => item.isOrder === true)
                setOrderContact(order[0]);
                setDeliveryContact(delivery[0]);
            })
            .catch(error => {
                console.log("error ", error.response);
            })
    }, [])
    return (
        <>
            <div className="px-3 md:px-8 mt-4">
                <h2 className="text-4xl px-3 font-bebas tracking-tight text-gray-800 mb-6">Paramè<span className='text-snack-pink-original'>tres</span></h2>
                <div className="mx-auto">
                    <Tab.Group>
                        <Tab.List className="flex" >
                            <Tab as={Fragment} >
                                {({ selected }) => (
                                    <div className='w-1/3 flex items-end cursor-pointer'>
                                        <div
                                            className={`focus:outline-none  w-full text-center pt-3 rounded-tl-xl ${selected ? ' border-t-2 border-r-2 border-l-2 rounded-t-xl  border-snack-pink-original bg-white pt-7  text-gray-800' : ' border-t-2 border-l-2 border-gray-300 bg-white text-black'
                                                }`}
                                            style={{ marginBottom: selected ? -2 : 0, paddingBottom: selected ? 2 : 0 }}
                                        >
                                            Adresse de l'enreprise
                                        </div>
                                    </div>
                                )}
                            </Tab>
                            <Tab as={Fragment} >
                                {({ selected }) => (
                                    <div className='w-1/3 flex items-end cursor-pointer'>
                                        <div
                                            className={`focus:outline-none  w-full text-center pt-3 ${selected ? ' border-t-2 border-r-2 border-l-2 rounded-t-xl border-snack-pink-original pt-7  bg-white text-gray-800' : ' border-t-2 border-gray-300 bg-white text-black'
                                                }`}
                                            style={{ marginBottom: selected ? -2 : 0, paddingBottom: selected ? 2 : 0 }}
                                        >
                                            Contact de facturation
                                        </div>
                                    </div>
                                )}
                            </Tab>
                            <Tab as={Fragment} >
                                {({ selected }) => (
                                    <div className='w-1/3 flex items-end cursor-pointer'>
                                        <div
                                            className={`focus:outline-none w-full text-center pt-3 rounded-tr-xl ${selected ? 'border-t-2 border-r-2 border-l-2 rounded-t-xl border-snack-pink-original pt-7  bg-white text-gray-800' : ' border-t-2 border-r-2 border-gray-300 bg-white text-black'
                                                }`}
                                            style={{ marginBottom: selected ? -2 : 0, paddingBottom: selected ? 2 : 0 }}
                                        >
                                            Contact de livraison
                                        </div>
                                    </div>
                                )}
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className={`bg-white rounded-b-xl shadow-xl  flex-wrap border-2 border-snack-pink-original`}>
                            <Tab.Panel className={classNames(
                                'p-3'
                            )}>
                                <Adress />
                            </Tab.Panel>
                            <Tab.Panel className={classNames(
                                'p-3'
                            )}>
                                <Contacts isDelivery={false} isOrder={true} user={userInfos} contact={OrderContact} />
                            </Tab.Panel>
                            <Tab.Panel className={classNames(
                                'p-3'
                            )}>
                                <Contacts isDelivery={true} isOrder={false} user={userInfos} contact={DeliveryContact} />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>

                </div>
            </div>
        </>
    )
}
export default Settings;