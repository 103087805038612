import { ButtonHTMLAttributes } from 'react';
import Loader from 'react-loader-spinner';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string, 
    disabled?:boolean,
    color?: string,
    width?:number,
    rounded?: string,
    loading?: boolean
}

function CustomButton({label, disabled = false, color, width, rounded, loading=false,  ...props}: ButtonProps) {

    return (
       /*  <button {...props} disabled={disabled} style={{width:width}}
        className={`${rounded} px-4 py-3 ${disabled ? "opacity-40 cursor-not-allowed bg-grey" : ""} ${!transparent ? "text-white shadow-lg bg-" + variant : "text-" + variant} ${border ? "border-2 border-" + variant : " border-transparent"} rounded-md border-2  font-bold outline-none flex items-center justify-center  2xl:text-base text-sm transform active:scale-75 transition-transform hover:opacity-80`}>
            

            {loading ? <Loader type="Oval" color="#fff" height={20} width={20} /> : label}
            
        </button> */
    <></>
    );
}
export default CustomButton;