import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import ProfilePicture from '../../assets/images/user_avatar.png';
import { useNavigate } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import axios from 'axios';
import axios1 from '../../features/axiosInstances/axios';
import React, { useEffect, useState } from 'react';
import Image from "@material-tailwind/react/Image";
import { disconnectUser, user } from '../../features/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Swal from 'sweetalert2';
import { cart_items, setCart, cartInfos } from '../../features/cartSlice';
import { cartItem } from '../../features/dataInterface';
import Loader from 'react-loader-spinner';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ArowDown, Cart } from './icons';
import { axiosDash } from '../../features/axiosInstances';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function UserBar({ showSidebar, setShowSidebar }) {
    const [showCart, setShowCart] = useState(false)
    const [variants_cart, setVariantsCart] = useState(null)


    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userInfos = useAppSelector(user)
    const items = useAppSelector(cart_items)
    const cart = useAppSelector(cartInfos)

    const handleKeyUp = (event) => {
        var modal = document.getElementById('cartmodal');
        var btn = document.getElementById('cartbtn');
        var goToCart = document.getElementById('gotoCartBtn');

        const target = event.target as Element;


        if ((target.parentNode !== btn && target.parentNode.parentNode !== btn) && (target !== modal && target.parentNode !== modal) && (target !== goToCart) && !target.classList.contains("simplebar-content")) {
            setShowCart(false)
        }
    }
    useEffect(() => {
        axios1.get("/cart", {
            headers: { "Authorization": `Bearer ${userInfos.token}` }
        })
            .then(res => {
                if (res.data) {
                    dispatch(setCart(res.data))
                    //check if pot and toppings are returned
                    console.log('cart ::', res.data);
                    setVariantsCart(res.data)
                }
            })
            .catch(err => {
                console.log(err.response);

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
        window.addEventListener('mouseup', handleKeyUp);
        return () => {
            window.removeEventListener('mouseup', handleKeyUp);
        }
    }, [])


    const logout = () => {
        //https://api-dashboard.snack-time.fr/api/logout
        axiosDash.post("/logout")
            .then(res => {
                dispatch(disconnectUser());
                navigate('/');
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue !',
                })
            })
    }
    const goToCart = () => {
        navigate('panier');
        setShowCart(false);
    }

    return (
        <nav className="shadow-sm">
            <div className="container bg-white md:bg-opacity-0 max-w-full mx-auto flex items-center justify-between  md:pl-8">
                {/* mobile open close button */}
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('left-0')}
                    >
                        <Icon name="menu" size="2xl" color="gray" />
                    </Button>
                    <div className={`z-50  absolute ${showSidebar === 'left-0' ? 'w-full h-full inset-0' : 'hidden'} bg-black bg-opacity-40`}>
                        <div
                            className={`absolute  top-6 md:hidden ${showSidebar === 'left-0' ? 'right-6' : '-left-64'} transition-all duration-300 bg-white rounded-full`}
                        >
                            <Button
                                color="transparent"
                                buttonType="link"
                                size="lg"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => setShowSidebar('-left-64')}
                            >
                                <Icon name="close" size="2xl" color="gray" />
                            </Button>
                        </div>
                    </div>
                </div>
                {/* end mobile open close button */}
                <div className="flex justify-end bg-white  md:p-6 p-3 items-center w-full">
                    <div className="flex  flex-row  items-center">
                        <div className="md:relative font-sans xl:mr-14 pt-2 flex items-center lg:mt-0 lg:ml-6 text-black ">
                            <div className='mr-6'>
                                {cart !== null &&

                                    <span className='font-sofiapro text-base text-snack-gray-menu'>TOTAL <b className='font-bold text-snack-pink-original ml-2'>€{cart.total.toFixed(2)}</b></span>
                                }
                            </div>
                            <div id="cartbtn" className="relative flex cursor-pointer hover:text-snack-green-original" onClick={() => {

                                setShowCart(!showCart)
                                console.log("state:", !showCart);
                                if (!showCart) {

                                    axios1.get("/cart", {
                                        headers: { "Authorization": `Bearer ${userInfos.token}` }
                                    })
                                        .then(res => {
                                            console.log("success", res.data);
                                            setVariantsCart(res.data);
                                        })
                                        .catch(err => {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Oops...',
                                                text: 'Une erreur est survenue !',
                                            })
                                        })
                                }

                            }} >
                                <Cart />
                                {items && items.length > 0 ?
                                    <span className="absolute -right-2 -top-3 rounded-full bg-snack-pink-original w-4 h-4 p-0 m-0 text-white font-mono text-xs  text-center">
                                        {items.length}
                                    </span>
                                    :
                                    null}
                            </div>
                            {showCart ?

                                items.length !== 0 ?
                                    <div id="cartmodal" className="z-40 flex flex-col shadow-lg	justify-between md:w-80 w-full rounded-lg overflow-y-hidden right-0 top-12 absolute my-5 bg-white">
                                        <SimpleBarReact className='px-5 py-5' style={{ maxHeight: "240px" }}>
                                            <div className='pointer-events-none '>
                                                {items.map((item: cartItem, index: number) => (
                                                    <div className={`mb-3 pointer-events-none ${index !== items.length - 1 && 'border-b'}`} key={index}>
                                                        <div className="flex flex-row mb-2 items-center justify-between ">
                                                            <div className="flex flex-col pointer-events-none">
                                                                {/*  <div className="h-12 w-12">
                                                                    <img className="object-cover object-center h-12 w-12  rounded-xl" src={item.product && item.product.cover.main} alt="" />
                                                                </div> */}
                                                                {/* <img src={item.product && item.product.cover.main} alt="dessert image" className="object-center object-cover rounded lg:w-1/5 md:w-1/5 pointer-events-none" /> */}
                                                                <p className="px-2 pointer-events-none font-sofiapro text-sm text-snack-gray-dark">{item.product && item.amount + "x " + item.product.title}</p>
                                                                <span className="px-2 pointer-events-none font-sofiapro text-sm text-snack-gray-dark"> {item.toppings.length > 0 && "  Personnalisé"}</span>

                                                            </div>
                                                            <p className="pointer-events-none font-sofiapro text-snack-pink-original">€{item.pot && item.pot.price.toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>

                                        </SimpleBarReact>

                                        <div className='px-5 pb-5'>
                                            <div className="flex flex-row justify-between pointer-events-none border-t pt-5 font-sofiapro">
                                                <p className='text-snack-pink-original pointer-events-none'>Total</p>
                                                <p className='text-snack-pink-original font-bold pointer-events-none font-sofiapro'>{variants_cart.total.toFixed(2)}€</p>
                                            </div>
                                            <button id='gotoCartBtn' onClick={goToCart} className="w-full mt-2 bg-snack-pink-original hover:bg-snack-pink-light hover:text-snack-pink-original text-snack-pink-light py-2 px-4 rounded-full inline-flex justify-center items-center">
                                                Voir détails
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div id="cartmodal" className="z-50 flex flex-col shadow-md	 justify-between w-72 rounded-lg overflow-y-hidden  right-0 top-12  absolute my-5 bg-white ">
                                        <div className='h-16 flex justify-center items-center'>

                                            <div className='mr-2' > <Cart /> </div> Votre panier est vide
                                        </div>


                                    </div>
                                /* <div id="cartmodal" onBlur={() => setShowCart(false)} className="z-50 items-center justify-items-center flex flex-col w-56 rounded-lg overflow-hidden h-60 right-0  absolute my-5 bg-white ">
                                    <Loader
                                        type="Rings"
                                        color="#00BFFF"
                                        height={80}
                                        width={80}
                                    />
                                </div>*/
                                : null}
                        </div>

                        {/* user menu and profile pic*/}
                        <div className="ml-6 px-2 flex">

                            <Menu as="div" className="z-40 relative inline-block text-left">
                                <div className='flex'>
                                    <Menu.Button className="inline-flex justify-center items-center w-full focus:outline-none">
                                        <div className="w-12 rounded-3xl  outline-none ring-1 ring-offset-2 ring-offset-gray-100 ring-snack-pink-original">
                                            <Image src={ProfilePicture} rounded />
                                        </div>
                                        <span className='block text-snack-gray-menu font-sofiapro text-base ml-4' >Bonjour {userInfos.profile.first_name} </span>
                                        <div className='ml-4'>
                                            <ArowDown />
                                        </div>
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                        <div className="py-5 flex flex-col px-5 text-gray-700 text-sm">
                                            <p className="font-normal ">Connecté en tant que</p>
                                            <p className="font-bold" >{userInfos.profile.email}</p>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => navigate("/compte")}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            ' px-4 py-2 text-sm flex cursor-pointer justify-items-center align-middle'
                                                        )}
                                                    >

                                                        <Icon name="settings" size="xl" color="gray" />
                                                        <div className="ml-2">
                                                            <text>Gestion du compte</text>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => navigate("/parametres")}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            ' px-4 py-2 text-sm flex cursor-pointer justify-items-center align-middle'
                                                        )}
                                                    >

                                                        <Icon name="tune" size="xl" color="gray" />
                                                        <div className="ml-2">
                                                            <text>Gestion de l'entreprise</text>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </div>

                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={logout}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            ' px-4 py-2 text-sm flex cursor-pointer justify-items-center align-middle'
                                                        )}
                                                    >
                                                        <Icon name="logout" size="xl" color="gray" />
                                                        <div className="ml-2">
                                                            <text>Se Déconnecter</text>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                        </div>

                    </div>
                </div>
            </div>
        </nav >
    )
}

export default UserBar;
