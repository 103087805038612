import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { cartItem } from './dataInterface';


export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    id: 0,
    
    sub_total: 0,
    delivery_fee: 0,
    total: 0,
    items: [] as cartItem[]
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    emptyCart:  (state) =>{
      state.sub_total = 0;
      state.delivery_fee = 0;
      state.total = 0;
      state.items = [];
    },
    setCart: (state, action: PayloadAction<any>) => {
      state.id = action.payload.id;
      state.sub_total = action.payload.sub_total;
      state.delivery_fee = action.payload.delivery_fee;
      state.total = action.payload.total;
      state.items = action.payload.items;
    },
    addItems: (state, action: PayloadAction<any>) => {
      state.items = [...state.items, action.payload.item];
      state.total = action.payload.total;
      state.sub_total = action.payload.sub_total;
      state.delivery_fee = action.payload.delivery_fee;
    },
    updateDetails: (state, action: PayloadAction<any>) => {
      state.total = action.payload.total;
      state.sub_total = action.payload.sub_total;
      state.delivery_fee = action.payload.delivery_fee;
    },
    removeCartItem: (state, action) => {
      let price = action.payload.price;
      state.items = state.items.filter(item => item.id !== action.payload.id);
      state.total = state.total - (price * action.payload.quantity);
      state.sub_total = state.sub_total - (price * action.payload.quantity);
    },
  },
});

export const { setCart, addItems, updateDetails, removeCartItem, emptyCart } = cartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const cart_id = (state: RootState) => state.cart.id;
export const cartInfos = (state: RootState) => state.cart;
export const cart_items = (state: RootState) => state.cart.items;

export default cartSlice.reducer;
