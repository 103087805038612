import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { address, profile } from './dataInterface';


export const userSlice = createSlice({
  name: 'user',
  initialState: {
    connected: false,
    userInfos: {
      token: "",
      profile: {} as profile,
      address: 0,
      savedAddress: 0
    }
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    connectUser: (state, action: PayloadAction<any>) => {
      state.connected = true;
      state.userInfos.token = action.payload.token;
      state.userInfos.profile = action.payload.user;
    },
    updateUser: (state, action: PayloadAction<any>) => {
      state.userInfos.profile.first_name = action.payload.first_name;
      state.userInfos.profile.last_name = action.payload.last_name;
      state.userInfos.profile.email = action.payload.email;
      //state.userInfos.profile.phone_number = action.payload.phone_number;
    },
    updatePhoneNumber: (state, action) => {
      state.userInfos.profile.phone_number = action.payload;
    },
    disconnectUser: (state) => {
      state.connected = false;
      state.userInfos.token = "";
      state.userInfos.address = null;
      state.userInfos.savedAddress = null;
      state.userInfos.profile = {
        id: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        device: null,
        is_active: null,
        is_blocked: null,
        lang: null,
        invite_code: null,
        is_entreprise: null,
        monthly_subscribed: false
      }
    },
    addAddress: (state, action) => {
      state.userInfos.address = action.payload;
    },
    addSavedAddress: (state, action) => {
      state.userInfos.savedAddress = action.payload;
    },
  },
});

export const { connectUser, disconnectUser, updateUser, updatePhoneNumber, addAddress, addSavedAddress } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const authenticated = (state: RootState) => state.user.connected;
export const user = (state: RootState) => state.user.userInfos;

export default userSlice.reducer;
