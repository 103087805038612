import React, { useEffect, useState } from 'react';


interface HoursBoxProps { }

const HoursBox = ({ hour, day, selected, onPress }) => {

    const equalHours = (selected.time.hours == hour.time.hours);
    const equalMins = (selected.time.mins == hour.time.mins);
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        let date = new Date()
        //console.log(date.getHours(), hour.time.hours, date.getHours() >= hour.time.hours);

        /* if ((date.getHours() >= hour.time.hours)) {
            setDisabled(true)
        } */
    }, [])


    return (

        <button onClick={onPress} className={`cursor-pointer mr-3 focus:outline-none ${(disabled && (new Date().getDay() == day.fullDay.getDay())) ? "cursor-not-allowed opacity-30" : ""} `} /* disabled={(disabled) && (new Date().getDay() == day.fullDay.getDay())} */>
            <div className={`${(equalHours && equalMins) ? 'bg-snack-green-original ' : 'bg-white '} border border-snack-green-original shadow rounded flex flex-row items-center justify-center  w-16 h-10 `}>
                <p className={`${equalHours && equalMins ? 'text-white' : 'text-snack-green-original '} font-SofiaPro-600 text-base font-bold`} >
                    {hour.time.hours}h{hour.time.mins != 0 ? hour.time.mins : "00"}</p>
            </div >
        </button>
    );
}
export default HoursBox;
