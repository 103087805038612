import axios from 'axios';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { cartInfos, cart_items, emptyCart, setCart } from '../features/cartSlice';
import { addAddress, addSavedAddress, user } from '../features/userSlice';
import { cartItem } from '../features/dataInterface';
import CartItem from './components/CartItem';
import { useNavigate } from "react-router-dom";
import OrderHours from './components/OrderHours';
import { order } from '../features/OrderSlice';
import Select from 'react-select'
import SimpleBarReact from 'simplebar-react';
import { number } from 'yup/lib/locale';
import { createOrderItems, createOrderTransaction, createUserOrder, deleteCartItems, getAllIngredientsWithPrice, getSavedAdress, getUserAdr } from '../features/services';
import CustomButton from './components/CustomButton'
import { axiosApi } from '../features/axiosInstances';

function Cart() {
    const userInfos = useAppSelector(user)
    const cartData = useAppSelector(cartInfos)
    const pickedDate = useAppSelector(order)
    const [adrdetails, setAdrdetails] = useState(null)
    const [payementMode, setPayementMode] = useState(null)
    const [allIngredients, SetAllIngredients] = useState(null)

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const options = [
        { label: "Espèces", value: "COD" },
        { label: "Virement bancaire", value: "TXN" }
    ]
    useEffect(() => {
        getAllIngredientsWithPrice()
            .then(res => {
                SetAllIngredients(res.data)
            })
            .catch(err => {
                console.log("err in ings");
            })
    }, []);

    useEffect(() => {
        if (userInfos.profile.monthly_subscribed) {
            setPayementMode("TXN")
            console.log("payement mode is txn");
            
        } else {
            setPayementMode("COD")
            console.log("payement mode is cash");

        }
    }, [userInfos.profile]);


    useEffect(() => {

        getUserAdr(userInfos.address)
            .then((res) => {
                setAdrdetails(res.data)
            })
            .catch((err) => {
                console.log("err de recuperation d'adresse");
            })

    }, [userInfos.address])

    useEffect(() => {

        getSavedAdress(userInfos.profile.id)
            .then(res => {
                if (res.data[0]) {

                    dispatch(addAddress(res.data[0].address.id));
                    dispatch(addSavedAddress(res.data[0].id));
                    console.log(res.data[0].id)
                }
            })
            .catch(error => {
                console.log("error ", error);
            })
    }, []);

    const deleteAll = (id: number) => {
        let reqs = [];

        var promises = cartData.items.map((item) => {
            const req = deleteCartItems(item.id)
            reqs.push(req);
        })

        Promise.all(promises)
            .then(function (results) {
                axios.all(reqs)
                    .then(res2 => {
                        console.log("success delete");
                    })
                    .catch(error => {
                        console.log("err2", error.response);
                    })
            })
    }

    const confirm = () => {

        let orderBody = {
            mode: payementMode,
            should_delivery_at: new Date(pickedDate),
            should_delivery: true,
            saved_address_id: userInfos.savedAddress,
        }
        console.log("body   :", orderBody);

        axiosApi.post("/orders/confirm", orderBody, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${userInfos.token}`,
            }
        })
            .then(res => {
                dispatch(emptyCart())
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Commande confirmée avec succès',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(err => {
                console.log("error ", err.response);
            })
    }

    const confirmOrder = () => {
        let orderBody = {
            mode: payementMode,
            should_delivery_at: new Date(pickedDate),
            should_delivery: true,
            saved_address_id: userInfos.address,
        }
        axiosApi.post("/orders/confirmEntreprise", orderBody, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${userInfos.token}`,
            }
        })
            .then(res => {
                console.log(res);

                dispatch(emptyCart());
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Commande confirmée avec succès',
                    showConfirmButton: false,
                    timer: 1500
                })

            })
            .catch(err => {
                console.log(err);
            })

        /*  const today = new Date()
     const tomorrow = new Date(today)
     let order = {
         "mode": payementMode,
         "status": "new",
         "subTotal": cartData.sub_total,
         "deliveryFee": cartData.delivery_fee,
         "total": cartData.total,
         "shouldDeliveryAt": new Date(pickedDate),
         "shouldDelivery": true,
         "createdAt": new Date(),
         "updatedAt": new Date(),
         "address": { "id": userInfos.address },
         "user": { "id": userInfos.profile.id }
     }
 
 
     createUserOrder(order)
         .then(res => {
             let reqs = [];
             const req2 = createOrderTransaction({
                 "status": "new",
                 "createdAt": new Date(),
                 "updatedAt": new Date(),
                 "order": { "id": res.data.id }
             })
             reqs.push(req2);
             var promises = cartData.items.map((item) => {
                 const req = createOrderItems({
                     "amount": item.amount,
                     "createdAt": new Date(),
                     "updatedAt": new Date(),
                     "order": { "id": res.data.id },
                     "variant": { "id": item.product.id },
                     "toppings": item.toppings,
                     "pot": { "id": item.pot.id },
                 })
                 reqs.push(req);
             })
 
             Promise.all(promises)
                 .then(function (results) {
                     axios.all(reqs)
                         .then(res2 => {
                             Swal.fire({
                                 toast: true,
                                 position: 'top-end',
                                 icon: 'success',
                                 title: 'Commande confirmée avec succès',
                                 showConfirmButton: false,
                                 timer: 1500
                             })
                             console.log("success order");
                             dispatch(emptyCart());
                             deleteAll(res.data.id);
                         })
                         .catch(error => {
                             console.log("err2", error.response);
                         })
                 })
         })
         .catch(error => {
             console.log("error ", error.response);
 
         }) */
    }
    return (
        <div className="md:ml-8 px-4 mt-4">
            <div className="md:rounded-xl  rounded-r-lg flex-wrap ">
                <div className="flex md:flex-row flex-col max-w-2xl mx-auto md:px-4 sm:pt-10 sm:px-6 lg:max-w-7xl lg:px-8 ">
                    <div className="md:w-4/6 w-full md:pr-7">
                        <div className="flex justify-between items-end pb-3 md:mr-3">
                            <h1 className="text-4xl font-bebas tracking-tight text-gray-800">Panier</h1>
                            <h2 className="font-bebas tracking-tight text-snack-pink-original text-2xl"><span className='text-snack-gray-dark'>{cartData ? cartData.items.length : 0}</span> Desserts</h2>
                        </div>
                        <div className='relative'>

                            <SimpleBarReact className='md:pr-3' style={{ maxHeight: "560px" }}>
                                <div className="flex min-w-min mb-3 bg-white rounded-xl shadow-md px-6 py-6 md:mr-3 sticky top-0">
                                    <div className='flex md:w-2/6' >
                                        <h3 className="font-semibold text-gray-600 text-xs uppercase md:w-1/2 w-72 min-w-min">Details Dessert</h3>
                                    </div>
                                    <div className='flex md:w-1/6' >
                                        <h3 className="font-semibold text-gray-600 text-xs uppercase md:w-1/2 w-72 min-w-min">Ingrédients Personnalisés</h3>
                                    </div>
                                    <div className='flex md:w-3/6' >
                                        <h3 className="font-semibold  text-gray-600 text-xs uppercase md:w-1/3 w-44 text-center min-w-min">Pot</h3>
                                        <h3 className="font-semibold  text-gray-600 text-xs uppercase md:w-1/3 w-44 text-center min-w-min">Quantité</h3>
                                        <h3 className="font-semibold  text-gray-600 text-xs uppercase md:w-1/3 w-44 text-center min-w-min">Prix Unitaire</h3>
                                        <h3 className="font-semibold  text-gray-600 text-xs uppercase md:w-1/3 w-44 text-center min-w-min">Prix Total</h3>
                                    </div>
                                </div>

                                {((cartData != undefined) && (cartData.items) && (cartData.items.length > 0)) ?

                                    cartData.items.map((item: cartItem, index) =>

                                        <CartItem key={index} item={item} allIngredients={allIngredients} />

                                    ) :
                                    <div className="flex min-w-min mb-3 bg-white rounded-xl shadow-md px-6 py-6 md:mr-3 sticky top-0">
                                        <div className='flex w-full align-middle' >
                                            <h3 className="font-semibold text-gray-600 text-xs uppercase w-full text-center ">Aucun dessert au panier</h3>
                                        </div>
                                    </div>
                                }

                            </SimpleBarReact>
                        </div>

                    </div>

                    <div id="summary" className="md:w-2/6 w-full mt-4">
                        <h1 className="text-4xl  font-bebas tracking-tight text-gray-800 pb-3">Détails commande</h1>

                        <div className='p-4 mb-3 bg-white shadow-md rounded-xl'>
                            <label className="font-bold inline-block mb-3 text-sm uppercase">Adresse</label>
                            <div className="flex flex-row justify-between items-center text-gray-600 w-full text-sm">
                                {adrdetails ? <span>{adrdetails.streetName + ' ' + adrdetails.postalCode + ', ' + adrdetails.city}</span> :
                                    <span>Encore pas d'adresse enregistrée</span>}
                                <button className="bg-snack-pink-original text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                                    onClick={() => { navigate('/parametres') }}>
                                    {adrdetails ? 'Modifier' : 'Ajouter'}
                                </button>
                            </div>

                        </div>
                        <div className='bg-white shadow-md rounded-xl p-4 mb-3'>
                            <label className="font-medium inline-block text-sm uppercase">Date et heure de livraison</label>
                            <div className="block  text-gray-600 w-full text-sm">
                                <OrderHours />
                            </div>
                        </div>
                        <div className='bg-white shadow-md rounded-xl p-4 mb-3' >
                            <label className="font-medium inline-block text-sm uppercase mb-3">Mode de paiement</label>

                            {/* <Select className="w-full"
                                options={options}
                                onChange={selectedOption => {
                                    setPayementMode(selectedOption.value);
                                }}
                            /> */}
                            <div className="block text-gray-600 w-full text-sm">
                                {userInfos.profile.monthly_subscribed ?
                                    <span>Virement bancaire</span>
                                    :
                                    <span>En Espèces</span>
                                }
                            </div>
                        </div>
                        <div className='bg-white shadow-md rounded-xl p-4 mb-3'>
                            <label className="font-medium inline-block  text-sm uppercase mb-3">Prix de livraison</label>
                            {/*  <select className="block p-2 text-gray-600 w-full text-sm">
                                <option>Livraison gratuite - $0.00</option>
                            </select> */}
                            <div className="block text-gray-600 w-full text-sm">
                                {cartData.delivery_fee === 0 ?
                                    <span>Livraison gratuite - 00.00€</span>
                                    :
                                    <span>Frais de livraison - {cartData.delivery_fee}€</span>
                                }
                            </div>
                        </div>

                        {/* <div className="flex justify-between ">
                            <span className="font-semibold text-sm uppercase">{cartData ? cartData.items.length : 0} Desserts</span>
                            <span className="font-semibold text-sm">€{cartData ? cartData.sub_total : 0}</span>
                        </div> */}
                        <div className="bg-white shadow-md p-4 rounded-xl mb-3">
                            <div className="flex font-semibold justify-between  text-sm uppercase mb-5">
                                <span>Prix total</span>
                                <span>{cartData ? cartData.total.toFixed(2) : 0}€</span>
                            </div>
                            <button disabled={!adrdetails || !payementMode || !pickedDate} className={`${(!adrdetails || !payementMode || !pickedDate) ? "bg-snack-gray-dark bg-opacity-50 cursor-not-allowed" : "bg-snack-green-original hover:bg-green-800"} font-semibold rounded  py-3 text-sm text-white uppercase w-full`} onClick={confirmOrder}>Checkout</button>
                        </div>
                        <CustomButton />

                    </div>

                </div>
            </div>
        </div>
    )
}
export default Cart;
