import axios from "axios"
import { useState } from "react"
import Swal from "sweetalert2"
import { useAppSelector } from "../app/hooks"
import Input from "@material-tailwind/react/Input"
import { address, profile } from '../features/dataInterface';
import { axiosApi, axiosDash } from "../features/axiosInstances"

interface ContactProps {
    isDelivery: boolean
    isOrder: boolean
    user: {
        token: string,
        profile: profile
        address: number
        savedAddress: number
    }
    contact: any
}

const Contacts = ({ isDelivery, isOrder, user, contact }: ContactProps) => {

    const [firstName, setFirstName] = useState(contact.prenom ? contact.prenom : "")
    const [firstNameError, setFirstNameError] = useState(null)
    const [lastName, setLastName] = useState(contact.nom ? contact.nom : "")
    const [lastNameError, setLastNameError] = useState(null)
    const [email, setEmail] = useState(contact.mail ? contact.mail : "")
    const [emailError, setEmailError] = useState(null)
    const [phone, setPhone] = useState(contact.phone ? contact.phone : "")
    const [phoneError, setPhoneError] = useState(null)


    const updateContact = () => {
        let updateContact = {
            "prenom": firstName,
            "nom": lastName,
            "mail": email,
            "phone": parseInt(phone),
        }

        axiosDash.patch(`/contacts/${contact.id}`, updateContact, { headers: { "content-type": "application/merge-patch+json" } })
            .then(res => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: contact.isOrder ? `Contact de facturation modifiées!` : `Contact de livraison modifiées!`,
                    showConfirmButton: false,
                    timer: 1500
                })

            })
            .catch(error => {
                console.log("error ", error.response);

            })
    }
    return (
        <div style={{ height: 300, width: '100%', }}>
            <div className='mt-10'>
                <h3 className="font-semibold text-gray-600 text-xs uppercase my-2 w-2/5 ">Details  {contact.isOrder ? " de facturations": " de livraison"}</h3>

                <div className=" my-5 flex flex-row justify-evenly">

                    <div className="w-1/2 mx-2">
                        <Input
                            type="text"
                            color="lightBlue"
                            outline={true}
                            placeholder="Nom"
                            value={lastName}
                            error={lastNameError}
                            onBlur={() => {
                                if (lastName === "") {
                                    setLastNameError("Nom obligatoire")
                                }
                            }}
                            onChange={(e) => {
                                if (e.target.value.match("^^[_A-z0-9]*((-|\s)*[_A-z0-9])*$") !== null) {
                                    setLastName(e.target.value);
                                    setLastNameError("")
                                }
                            }}
                        />
                    </div>
                    <div className="w-1/2 mx-2">
                        <Input
                            type="text"
                            color="lightBlue"
                            outline={true}
                            placeholder="Prénom"
                            value={firstName}
                            error={firstNameError}
                            onBlur={() => {
                                if (firstName === "") {
                                    setFirstNameError("Prénom obligatoire")
                                }
                            }}
                            onChange={(e) => {
                                if (e.target.value.match("^^[_A-z0-9]*((-|\s)*[_A-z0-9])*$") !== null) {
                                    setFirstName(e.target.value)
                                    setFirstNameError("")
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="my-5 flex flex-row justify-evenly">

                    <div className="w-1/2 mx-2">
                        <Input
                            type="email"
                            color="lightBlue"
                            outline={true}
                            placeholder="Email"
                            value={email}
                            error={emailError}
                            onBlur={() => {
                                if (email === "") {
                                    setEmailError("E-mail valide obligatoire")
                                } else if (email.match("^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$") === null) {
                                    setEmailError("E-mail format invalide")
                                }
                            }}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                if (email.match("^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$") === null) {
                                    setEmailError("E-mail format invalide")
                                } else {
                                    setEmailError("")
                                }
                            }}
                        />
                    </div>
                    <div className="w-1/2 mx-2">
                        <Input
                            type="email"
                            color="lightBlue"
                            outline={true}
                            placeholder="Numéro de téléphone"
                            value={phone}
                            error={phoneError}
                            onBlur={() => {
                                if (phone === "") {
                                    setPhoneError("Numéro de téléphone valide obligatoire")
                                }
                            }}
                            onChange={(e) => {
                                if
                                    (e.target.value.match("^^[0-9]*((-|\s)*[0-9])*$") !== null) {
                                    setPhone(e.target.value)
                                    setPhoneError("")
                                }
                            }}
                        />
                        {/* {phone.toString().match("^[0-9]+$") === null && <p className="text-snack-pink-original font-normal text-sm">E-mail valide obligatoire</p>} */}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                        onClick={updateContact}>
                        Enregistrer
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Contacts